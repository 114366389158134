import logo from "./aday_logo.png";
import logo_white from "./aday_logo_white.png";

import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import "./App.css";
import "./symbols.css";
import { Companies } from "./pages/Companies";
import { Candidates } from "./pages/Candidates";
import { Home } from "./pages/Home";
import { Projects } from "./pages/Projects";
import { Admin } from "./pages/Admin";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { jwtDecode } from "jwt-decode";
import { ProjectPage } from "./pages/ProjectPage";
import { Container, Image } from "react-bootstrap";
import { useEffect, useState } from "react";

function App() {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string>('/projectfiles/avatar_anonymous.png');
  useEffect(() => {
    console.log("picture: ", auth?.user?.profile?.picture);
    if (auth.user?.profile?.picture !== undefined) {
      setProfilePicture(auth.user.profile.picture);
    }
    else {
      setProfilePicture('/projectfiles/avatar_anonymous.png');
    }
    if (!hasAuthParams() &&
        !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
        !hasTriedSignin
    ) {
        console.log("No auth params, trying signin");
        //auth.signinRedirect();
        setHasTriedSignin(true);
    }
}, [auth, hasTriedSignin]);
  auth.events.addUserLoaded((user) => {
    console.log("User signed in ", user);
    if (user) {
      var access_token_payload = jwtDecode(user.access_token) as any;
      var id_token_payload = user.id_token
        ? (jwtDecode(user.id_token) as any)
        : undefined;
      var roles = access_token_payload["realm_access"]["roles"];
      
      console.log("decoded access_token: ", access_token_payload);
      console.log("decoded id_token: ", id_token_payload);
      console.log("roles: ", roles);
    }
  });
  function logout() {
    auth.removeUser();
    //auth.signoutRedirect({ post_logout_redirect_uri: window.location.origin });
  }
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    
    return (
      <BrowserRouter>
        <div className="header">
          <Navbar expand="md"  className="navbar-dark">
            <Container fluid>
            <Navbar.Brand className="bg-transparentx">
              <img
                src={logo_white}
                height="30px"
                className="d-inline-block align-top"
                alt="Watego logo"
                style={{ marginBottom: "6px" }}
              />{" "}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <Nav className="me-auto" defaultActiveKey="/">
                <Nav.Link
                  href="#home"
                  as={Link}
                  to="/"
                  eventKey="/"
                  className="me-auto "
                  active
                >
                  <i className="bi-house text-yellow-100"> </i> Hem
                </Nav.Link>
                <Nav.Link
                  href="#projects"
                  as={Link}
                  to="/projects"
                  eventKey="/projects"
                >
                  <i className="bi-list-check"> </i> Projekt
                </Nav.Link>
                <Nav.Link
                  href="#candidates"
                  as={Link}
                  to="/candidates"
                  eventKey="/candidates"
                >
                  <i className="bi-person-bounding-box"> </i>Kandidater
                </Nav.Link>
                <Nav.Link
                  href="#companies"
                  as={Link}
                  to="/companies"
                  eventKey="/companies"
                >
                  <i className="bi-buildings"> </i> Kunder
                </Nav.Link>
                <NavDropdown
                  title={
                    <span className="bi bi-person-fill-gear">
                      {" "}
                      Administration
                    </span>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    href="#tenants"
                    as={Link}
                    to="/admin"
                    eventKey="/admin"
                  >
                    Rekryteringsföretag
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#users">Användare</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Rapporter
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <NavDropdown
                title={
                  <div className="icon-badge-group">
                    <div className="icon-badge-container">
                      <i className="bi bi-bell-fill icon-badge-icon"></i>
                      <div className="icon-badge">6</div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                  </div>
                }
                id="basic-nav-dropdown"
                className="me-4"
                align="end"
              >
                <NavDropdown.Item href="#tenants" as={Link} to="/project/123">
                  <b>Bo Lundgren</b> har kommenterat{" "}
                  <Link to="/project/123">Målare</Link> till Hanssons Måleri A"
                </NavDropdown.Item>
              </NavDropdown>

              <Navbar.Text>
                <Image roundedCircle src={profilePicture} height={32} />
                &nbsp;
              </Navbar.Text>
              <NavDropdown
                align="end"
                title={
                  <span className="nav-tweak">{auth?.user?.profile.name}</span>
                }
              >
                <NavDropdown.Item onClick={logout}>Logga ut</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Inställningar
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
            </Container>
          </Navbar>
          
        </div>
        <div className="content">
          <Routes>
            <Route
              path="/projects/:id?"
              element={<Projects />}
              key={"route_projects"}
            ></Route>
            <Route
              path="/project/:id?"
              element={<ProjectPage />}
              key={"route_project"}
            ></Route>
            <Route
              path="/candidates"
              element={<Candidates />}
              key={"route_candidates"}
            ></Route>
            <Route
              path="/companies/:id?"
              element={<Companies />}
              key={"route_companies"}
            ></Route>
            <Route
              path="/admin"
              element={<Admin />}
              key={"route_admin"}
            ></Route>
            <Route path="/" element={<Home />} key={"route_home"}></Route>
          </Routes>
          <div className="version-bottom">{(window as any).env.build_info}<br/>{(window as any).env.build_nr}<br/>{(window as any).env.build_id}</div>
        </div>
      </BrowserRouter>
    );
  } else {
    return (
      <>
        {/* Landing/login page */}
        <div className="image-container"></div>
        <div className="lower-container"></div>
        <div className="bottom-container"></div>
        <div className="login-container">
          <div className="text-center">
            <img src={logo} alt="Aday logo" className="logo" />
            <p className="mt-2 ms-5 me-5 text-primary">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
            <p>
              <Button onClick={() => void auth.signinRedirect()}>Log in</Button>
            </p>
          </div>
        </div>
        <div className="version-bottom">{(window as any).env.build_info}<br/>{(window as any).env.build_nr}<br/>{(window as any).env.build_id}</div>
      </>
    );
  }
}

export default App;
