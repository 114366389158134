import { useState, useEffect, useRef } from "react";
import UploadService from "../services/FileUploadService";
import IFile from "../types/File";
import { AxiosProgressEvent } from "axios";
import { useAuth } from "react-oidc-context";
import { Badge, ListGroup, ListGroupItem } from "react-bootstrap";

interface ProgressInfo {
  fileName: string;
  percentage: number;
}

const FilesUpload: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [progressInfos, setProgressInfos] = useState<Array<ProgressInfo>>([]);
  const [message, setMessage] = useState<Array<string>>([]);
  const progressInfosRef = useRef<any>(null);
  const auth = useAuth();

  

  const selectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    let images: Array<string> = [];
    let files = event.target.files;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        images.push(URL.createObjectURL(files[i]));
      }
  
      setSelectedFiles(files);
      setProgressInfos([]);
      setMessage([]);
    }
  };

  const upload = (idx: number, file: File, fileType: string) => {
    let _progressInfos = [...progressInfosRef.current];
    return UploadService.upload(auth, file, fileType, (event:AxiosProgressEvent) => {
      _progressInfos[idx].percentage = event.total ? (Math.round(
        (100 * event.loaded) / event.total
      )) : 0;
      setProgressInfos(_progressInfos);
    })
      .then(() => {
        setMessage((prevMessage) => [
          ...prevMessage,
          file.name + ": Successful!"
        ]);
      })
      .catch((err: any) => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos(_progressInfos);

        let msg = file.name + ": Failed!";
        if (err.response && err.response.data && err.response.data.message) {
          msg += " " + err.response.data.message;
        }

        setMessage((prevMessage) => [
          ...prevMessage,
          msg
        ]);
      });
  };

  const uploadFiles = () => {
    if (selectedFiles != null) {
      const files = Array.from(selectedFiles);

      let _progressInfos = files.map((file) => ({
        percentage: 0,
        fileName: file.name
      }));

      progressInfosRef.current = _progressInfos;

      const uploadPromises = files.map((file, i) => upload(i, file, "CV"));

      setMessage([]);
    }
  };

  return (
    <div >
     
      <div className="row my-3">
        <div className="col-8">
          <label className="btn btn-default p-0">
            <input type="file" multiple accept="image/*, application/pdf" onChange={selectFiles} />
          </label>
        </div>

        <div className="col-4">
          <button
            className="btn btn-success btn-sm"
            disabled={!selectedFiles}
            onClick={uploadFiles}
          >
            Upload
          </button>
        </div>
      </div>
      <div className="d-inline-flex gap-2">
      {progressInfos && message.length === 0 &&
        progressInfos.length > 0 &&
        progressInfos.map((progressInfo: ProgressInfo, index: number) => (
            <Badge bg='info' key={index}>{progressInfo.fileName} - {progressInfo.percentage}%</Badge>
        ))}
      </div>
      {message.length > 0 && (
          <ListGroup>
            {message.map((item, i) => {
              return <ListGroupItem key={i}>{item}</ListGroupItem>;
            })}
          </ListGroup>
      )}
    </div>
  );
};

export default FilesUpload;