import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, Form, FormControl, FormGroup } from "react-bootstrap";
import {
  CandidateInfoHub,
  CreateProjectAction,
  InfoNode,
  Project,
  ProjectInfoHub,
} from "../../types/project";
import { useAuth } from "react-oidc-context";
import ProjectService from "../../services/ProjectService";
import { Typeahead } from "react-bootstrap-typeahead";
import { Company } from "../../types/company";
import CompanyService from "../../services/CompanyService";
import { Option } from "react-bootstrap-typeahead/types/types";
import InfoNodeEditor from "./InfoNodeEditor";
import CandidateService from "../../services/CandidateService";
import { Candidate } from "../../types/candidate";

export default function NewProjectForm({
  onSave,
}: {
  onSave: (project: Project) => void;
}) {
  const auth = useAuth();
  const currentUser: string = (auth && auth.user?.profile.name) ?? "";
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [companyAbout, setCompanyAbout] = useState<string>("");
  const [page, setPage] = useState(0);
  const companyOptions = useRef<Record<string, string>[]>([]);
  const candidateOptions = useRef<Record<string, string>[]>([]);
  const [formData, setFormData] = useState<CreateProjectAction>({
    title: "",
    position: "",
    companyName: "",
    companyId: "",
    recruiter: currentUser,
    desiredStartDate: "ASAP",
    coRecruiters: [],
    template: "standard"
  });

  const formTitles = ["Projekt", "Kandidater"];
 
  const submit = () => {
    ProjectService.postProject(auth, formData)
      .then((response) => {
        setErrorMessage("");
        onSave(response.data);
      })
      .catch((error) => {
        setErrorMessage("Error creating project: " + error);
      });
  };
  useEffect(() => {
    console.log("Getting companies..");
    CompanyService.getCompanies(auth).then((response) => {
      const companies = response.data.map((company: Company) => {
        return {
          id: company.id,
          name: company.name,
          imageId: company.avatarId,
          about: company.about,
        };
      });
      companyOptions.current = companies;
    });
    CandidateService.getCandidates(auth).then((response) => {
      const candidates = response.data.map((candidate: Candidate) => {
        return {
          id: candidate.id,
          name: candidate.name,
          imageId: candidate.avatarId,
          cvFileId: candidate.cvFileId,
        };
      });
      candidateOptions.current = candidates;
    });
  });
 
  const headerForm = () => {
    return (
      <div>
        <Form className="form-tight">
          <FormGroup className="col-md-12">
            <Form.Label>Roll</Form.Label>
            <Form.Control
              type="text"
              value={formData?.position}
              onChange={(e) =>
                setFormData({ ...formData, position: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup className="col-md-12">
            <Form.Label>Företag</Form.Label>
            <Typeahead
              id="basic-typeahead-multiple"
              labelKey="name"
              allowNew
              options={companyOptions.current}
              newSelectionPrefix="Företag "
              placeholder="Företag.."
              onChange={(e: Record<string, any>) => {
                if (!e) return;
                const companyName = e[0].name as string;
                const companyId = e[0].id as string;
                const companyAvatarId = e[0].imageId as string;
                const companyAbout = e[0].about as string;
                setCompanyAbout(companyAbout);
               
                const suggestedTitle =
                  formData.title === ""
                    ? formData.position + " till " + companyName
                    : formData.title;
                setFormData({
                  ...formData,
                  companyName: companyName,
                  companyId: companyId,
                  title: suggestedTitle,
                });
              }}
              selected={formData.companyName ? [formData.companyName] : []}
            />
          </FormGroup>
          <FormGroup className="col-md-12">
            <Form.Label>Projekt-namn</Form.Label>
            <Form.Control
              type="text"
              value={formData?.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup> 
            <Form.Label>Projekt-mall</Form.Label>
            <Form.Select
              onChange={(e) =>setFormData({...formData, template: e.target.value})}
            >
              <option value={""}>Välj mall..</option>
              <option value={"standard"}>Standard</option>
              <option value={"minimal"}>Minimialistisk</option>
              <option value={"manager"} disabled
            >Chefsposition</option>
              <option value={"creative"} disabled>
                Kreativa yrken med arbetsprover
              </option>
            </Form.Select>
          </FormGroup>
        </Form>
      </div>
    );
  };
  
 
  const candidateForm = () => {
    console.log("candidateOptions", candidateOptions.current);
    return (
      <div>
        <Form className="form-tight">
          <FormGroup className="col-md-4">
            <Form.Label>Kandidat</Form.Label>

            <Typeahead
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              options={candidateOptions.current}
              newSelectionPrefix="Kandidat "
              placeholder="Kandidat.."
              onChange={(e: Record<string, any>) => {
                if (!e) return;
                if (e.length === 0) return;
                const candidateIds: string[] = [];
                for (let i = 0; i < e.length; i++) {
                  const candidateName = e[i].name as string;
                  const candidateId = e[i].id as string;
                  const candidateImageId = e[i].imageId as string;
                  const candidateCvFileId = e[i].cvFileId as string;

                  const candidateInfoHub: CandidateInfoHub | undefined =
                    candidateId
                      ? {
                          candidateName: candidateName,
                          candidatetagLine: "-",
                          imageId: candidateImageId,
                          candidateId: candidateId,
                          infoNodes: [
                            {
                              type: "Media",
                              title: "CV",
                              files: [
                                {
                                  id: candidateCvFileId,
                                  fileName: "CV",
                                  contentType: "application/pdf",
                                },
                              ],
                            },
                          ],
                        }
                      : undefined;
                  candidateIds.push(candidateId);
                }
                setFormData({
                  ...formData,
                  candidateIds: candidateIds,
                });
              }}
            />
          </FormGroup>
        </Form>
      </div>
    );
  };

  const formDisplay = () => {
    if (page === 0) {
      return headerForm();
    } else if (page === 1) {
      return candidateForm();
    } else return <div>Page {page}</div>;
  };
  return (
    <div>
      <h4>{formTitles[page]}</h4>
      {formDisplay()}
      {errorMessage && (
        <Alert variant="danger" className="mt-2">
          {errorMessage}
        </Alert>
      )}
      <div className="d-flex mt-4 justify-content-between gap-2">
        <Button
          disabled={page === 0}
          onClick={(e) => setPage(page - 1)}
          variant="secondary"
        >
          Tillbaka
        </Button>
        <div className="d-flex justify-content-end">
          <Button
            hidden={page === formTitles.length - 1}
            onClick={(e) => setPage(page + 1)}
            variant="primary"
          >
            Nästa
          </Button>
          <Button
            onClick={(e) => submit()}
            variant="primary"
            className="ms-2"
            disabled={
              !(
                formData.companyName.length > 0 &&
                formData.position.length > 0 &&
                page === formTitles.length - 1
              )
            }
          >
            Skapa
          </Button>
        </div>
      </div>
    </div>
  );
}
