import React from "react";
import { Project } from "../../types/project";
import { Button } from "react-bootstrap";
import "./ProjectCard.css";

export default function ProjectCard({
  project,
  onDeleteClicked,
  onEditClicked,
  onOpenProjectClicked,
}: {
  project: Project;
  onDeleteClicked: (project: Project) => void;
  onEditClicked: (project: Project) => void;
  onOpenProjectClicked: (project: Project) => void;
}) {
  return (
    <div className="project-card">
      <div
        className="project-card-header"
        onClick={() => {
          onOpenProjectClicked(project);
        }}
        style={
          project.backgroundImageId
            ? {
                backgroundImage:
                  "url(/api/file/file/" + project.backgroundImageId + ")",
              }
            : {}
        }
      >
        <img
          src={"/api/file/file/" + project.projectInfoHub?.imageId}
          alt="project"
          className="project-card-logo"
        />
      </div>
      <div className="project-card-title">{project.title}</div>
      <div className="project-card-status">{project.status}</div>

      <div className="project-card-footer">
        <Button
          size="sm"
          title="Öppna i mind-map"
          variant="link"
          onClick={() => {
            onOpenProjectClicked(project);
          }}
        >
          <i className="bi bi-diagram-3"></i>
        </Button>
        <Button
          size="sm"
          variant="link"
          title="Redigera"
          onClick={() => {
            onEditClicked(project);
          }}
        >
          <i className="bi bi-pencil-square"></i>
        </Button>
        <Button
          size="sm"
          variant="link"
          title="Ta bort"
          onClick={() => {
            onDeleteClicked(project);
          }}
        >
          <i className="bi bi-trash"></i>
        </Button>
      </div>
    </div>
  );
}
