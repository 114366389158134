import React, { useEffect, useRef } from 'react'
import { Button, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { CandidateInfoHub } from '../../types/project';
import CandidateService from '../../services/CandidateService';
import { useAuth } from 'react-oidc-context';
import { Candidate } from '../../types/candidate';

export default function CandidateSelectDialog({show, onApply, onCancel}:{show:boolean, onApply: (candidateIds:string[]) => void, onCancel: () => void}) {
    const auth = useAuth();
    const candidateOptions = useRef<Record<string, string>[]>([]);
    const [candidateIds, setCandidateIds] = React.useState<string[]>([]);
    const [showModal, setShowModal] = React.useState<boolean>(show);
    const handleApply = () => {
        setShowModal(false);
        onApply(candidateIds);
    }
    const handleCancel = () => {
        setShowModal(false);
        onCancel();
    }
    useEffect(() => {
        CandidateService.getCandidates(auth).then((response) => {
          const candidates = response.data.map((candidate: Candidate) => {
            return {
              id: candidate.id,
              name: candidate.name,
              imageId: candidate.avatarId,
              cvFileId: candidate.cvFileId,
            };
          });
          candidateOptions.current = candidates;
        });
      }, []);
      useEffect(() => { 
        setShowModal(show);
      }, [show]);      
    return (
    <Modal show={show} onHide={handleCancel}>
    <Modal.Header closeButton>
      <Modal.Title>Lägg till kandidat</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormGroup>
        <FormLabel>Välj kandidat</FormLabel>
        <Typeahead
          id="basic-typeahead-multiple"
          labelKey="name"
          multiple
          options={candidateOptions.current}
          newSelectionPrefix="Kandidat "
          placeholder="Kandidat.."
          onChange={(e: Record<string, any>) => {
            if (!e) return;
            if (e.length === 0) return;
            const candidateIds: string[] = [];
            for (let i = 0; i < e.length; i++) {
              const candidateName = e[i].name as string;
              const candidateId = e[i].id as string;
              const candidateImageId = e[i].imageId as string;
              const candidateCvFileId = e[i].cvFileId as string;

              candidateIds.push(candidateId);
            }
            setCandidateIds(candidateIds);
          }}
        />
      </FormGroup>
    
    </Modal.Body>
    <Modal.Footer className="d-flex">
        <Button variant="secondary" onClick={handleCancel}>Avbryt</Button>
        <Button variant="primary" onClick={handleApply}>Lägg till</Button>
    </Modal.Footer>
    
  </Modal>
  )
}
