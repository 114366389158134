import React, { useState } from 'react'
import { FormField } from '../../types/project'
import { FormGroup, Form } from 'react-bootstrap';

export default function FormFieldEditor({formField} : {formField: FormField}) {
    switch (formField.type) {
        case "Radio":
          return (
            <>
              <div className="mt-2">{formField.question}</div>
              
              {formField.options.map((option, index) => {
                return (
                  <div key={index}>
                    <Form.Check
                      inline
                      type="radio"
                      label={option}
                      name={option}
                      onChange={(e) => {formField.values = [e.target.value]}}
                      checked={formField.values?.includes(option)}
                      id={"option_" + index}
                    />
                  </div>
                );
              })}
            </>
          );
          case "Checkbox":
          return (
            <>
              <div className="mt-2">{formField.question}</div>
              
              {formField.options.map((option, index) => {
                return (
                  <div key = {index}>
                    <Form.Check
                      inline
                      type="checkbox"
                      label={option}
                      name={option}
                      onChange={(e) => {formField.values = [e.target.value]}}
                      checked={formField.values?.includes(option)}
                      id={"option_" + index}
                    ></Form.Check>
                  </div>
                );
              })}
            </>
          );
          case "Select":
          return (
            <>
              <div className="mt-2">{formField.question}</div>
              
              {formField.options.map((option, index) => {
                return (
                  <div key={index}>
                    <Form.Select 
                      onChange={(e) => {formField.values = [e.target.value]}}
                      value={formField.values?.[0]}
                      id={"option_" + index}
                    />
                  </div>
                );
              })}
            </>
          );
          case "Text":
            return (
                <FormGroup className="mt-2">
                    <Form.Label>{formField.question}</Form.Label>
                    <Form.Control
                    type="text"
                    value={formField.values?.[0]}
                    onChange={(e) => {formField.values = [e.target.value]}}
                />
              </FormGroup>
            );
        default: return <div>Unknown FormField type: {formField.type}</div>
      }
}
