import React, { PropsWithChildren } from "react";
import { ReactNode } from "react"
import './DashboardSection.css'

type SectionProps = {
    title : string;
    className?: string;
  };
  
  const DashboardSection = ({ title, className, children }: PropsWithChildren <SectionProps>) => {
    return (
      <div className={className + ' dashboard-section'}>
        <h4 className="text-primary">{title}</h4    >
        {children}
      </div>
    );
  };
  
export default DashboardSection;