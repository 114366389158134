import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import CandidatesService from "../../services/CandidateService";
import { Candidate } from "../../types/candidate";
import './CandidateEditor.css';
import {
  Col,
  Form,
  FormGroup,
  InputGroup,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Dropzone from "react-dropzone";
import FileUploadService from "../../services/FileUploadService";
import ProcessSteps from "../ProcessSteps";
import { Document, Page, pdfjs } from "react-pdf";

export default function CandidateEditor({
  id,
  onClose,
  onSave,
  onCancel
}: {
  id?: string;
  onClose?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
}) {
  const AVATAR_MISSING = "/projectfiles/avatar-missing.png";
  const CV_MISSING = "/projectfiles/cv-missing.png";
  const CV_EXISTS = "/projectfiles/cv-exists.png";

  const auth = useAuth();
  const [original, setOriginal] = useState<Candidate>();
  const [edited, setEdited] = useState<Candidate>();
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showCvViewer, setShowCvViewer] = useState(false);
  useEffect(() => { 
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  },[]);
  useEffect(() => {
      console.log("useEffect. Id: ", id);
      if (id) {
        reload();
      } else {
        // New candidate mode
      setOriginal({} as Candidate);
      const newCandidate = {} as Candidate;
    }
  }, []);
  const reload = () => {
    if (!id) return;
    CandidatesService.getCandidate(auth, id).then((response) => {
      console.log("Reloading: ", response);
      setEdited(response.data);
      setOriginal(response.data);
    });
  };

  const onDropAccepted = (acceptedFiles: File[]) => {
    console.log("Accepted files: ", acceptedFiles);
    setShowLoading(true);
    FileUploadService.upload(auth, acceptedFiles[0], "image", null).then(
      (response) => {
        setShowLoading(false);
        if (response.status !== 200 && response.data?.length === 1) {
          alert("Error uploading file");
        } else {
          const imageId = response.data[0].id;
          console.log("Setting imageId: ", imageId);
          setEdited({ ...edited!, avatarId: imageId });
        }
      }
    );
  };
  const onDropCvAccepted = (acceptedFiles: File[]) => {
    console.log("Accepted CV file: ", acceptedFiles);
    setShowLoading(true);
    FileUploadService.upload(auth, acceptedFiles[0], "image", null).then(
      (response) => {
        setShowLoading(false);
        if (response.status !== 200 && response.data?.length === 1) {
          alert("Error uploading file");
        } else {
          const cvFileId = response.data[0].id;
          console.log("Setting imageId: ", cvFileId);
          setEdited({ ...edited!, cvFileId: cvFileId });
        }
      }
    );
  };

  const save = () => {
    if (original?.id) {
      // Edit mode
      CandidatesService.patchCandidate(
        auth,
        original as Candidate,
        edited as Candidate
      )
        .then((response) => {
          setErrorMessage("");
          //reload();
          onClose && onClose();
        })
        .catch((error) => {
          setErrorMessage("Fel: " + error);
        });
    } else {
      // New candidate mode
      CandidatesService.postCandidate(auth, edited as Candidate)
        .then((response) => {
          id = response.data.id;
          //reload();
          setErrorMessage("");
          onClose && onClose();
        })
        .catch((error) => {
          setErrorMessage("Fel: " + error);
        });
    }
  };
  if (showLoading) return <div>Loading...</div>;
  return (
    <>
      <Form className="row g-3 form-tight">
        <Col md="12" className="d-inline-flex">
            <div>
          <Dropzone onDropAccepted={onDropAccepted} accept={{
    'image/jpeg': ['.jpg','jpeg'],
    'image/png': ['.png','PNG'],
    
  }}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone-container"
                >
                  <input {...getInputProps()} />
                  <img
                    src={
                      edited?.avatarId
                        ? "/api/file/file/" + edited.avatarId
                        : AVATAR_MISSING
                    }
                    className="rounded"
                  />
                  {showLoading && (
                    <div className="uploading-text">Uploading..</div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
          <div className="d-flex">
            <small className="ms-2 text-secondary">Photo</small>
            { edited?.avatarId && <a href="#" className="ms-auto"><i className='bi bi-trash' onClick={() => setEdited({ ...edited!, avatarId: "" })}></i></a> }
          </div>
          </div>
          <div className="ms-2">
          <Dropzone onDropAccepted={onDropCvAccepted} accept={{
    'application/pdf': ['.pdf'],
  }}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone-container"
                >
                  <input {...getInputProps()} />
                  <img
                    src={edited?.cvFileId ? CV_EXISTS : CV_MISSING}
                    className="rounded"
                  />
                  {showLoading && (
                    <div className="uploading-text">Uploading..</div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
          <div className="d-flex">
            <small className="ms-2 text-secondary">CV</small>
            { edited?.cvFileId && <a href={'/api/file/file/' + edited.cvFileId} download='cv.pdf' className='ms-auto'><i className='bi bi-download'></i></a> }
            { edited?.cvFileId && <a href="#" className="ms-2"><i className='bi bi-trash' onClick={() => setEdited({ ...edited!, cvFileId: "" })}></i></a> }
            { edited?.cvFileId && <a href="#" className="ms-2"><i className='bi bi-eye' onClick={() => setShowCvViewer(true)}></i></a> }
            
          </div>
          </div>
          <FormGroup className="flex-fill ms-4">
            <Form.Label>Sammanfattning</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={edited?.summary ?? ""}
              onChange={(e) => {
                setEdited({ ...edited!, summary: e.target.value });
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Namn</Form.Label>
            <Form.Control
              value={edited?.name ?? ""}
              placeholder="name"
              onChange={(e) => {
                setEdited({ ...edited!, name: e.target.value });
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>E-post</Form.Label>
            <InputGroup>
              <Form.Control
                value={edited?.email ?? ""}
                placeholder=""
                type="email"
                onChange={(e) => {
                  setEdited({ ...edited!, email: e.target.value });
                }}
              />
              <Button href={"mailto:" + edited?.email} target="#" disabled={!edited?.email}><i className="bi bi-envelope"></i></Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Telefon</Form.Label>
            <InputGroup>
              <Form.Control
                value={edited?.phoneNumber ?? ""}
                placeholder=""
                type="phone"
                onChange={(e) => {
                  setEdited({ ...edited!, phoneNumber: e.target.value });
                }}
              />
              <Button href={"tel:" + edited?.phoneNumber}  target="#"><i className="bi bi-telephone-outbound"></i></Button>
            </InputGroup>
          </FormGroup>
        </Col>

        <Col md="4">
          <FormGroup>
            <Form.Label>Löneanspråk</Form.Label>
            <Form.Control
              placeholder=""
              value={edited?.expectedSalary ?? ""}
              onChange={(e) => {
                setEdited({ ...edited!, expectedSalary: e.target.value });
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Uppsägningstid</Form.Label>
            <Form.Control
              placeholder=""
              value={edited?.noticePeriod ?? ""}
              onChange={(e) => {
                setEdited({ ...edited!, noticePeriod: e.target.value });
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="8">
          <FormGroup>
            <Form.Label>LinkedIn-länk</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder=""
                type="url"
                value={edited?.linkedInUrl ?? ""}
                onChange={(e) => {
                  setEdited({ ...edited!, linkedInUrl: e.target.value });
                }}
              ></Form.Control>

              <Button href={edited?.linkedInUrl ?? ""} target="blank_" disabled={!edited?.linkedInUrl}>
                <i className="bi bi-box-arrow-up-right"></i>
              </Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Form.Label>Taggar</Form.Label>
            <Typeahead
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              allowNew
              options={[]}
              newSelectionPrefix="Lägg till ny tagg: "
              placeholder="Tag..."
              onChange={(e) => {
                console.log("Setting tags to ", e);
                setEdited({ ...edited!, tags: e as string[] });
              }}
              selected={edited?.tags ?? []}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Form.Label>Skills</Form.Label>
            <Typeahead
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              allowNew
              options={[]}
              newSelectionPrefix="Lägg till ny skill: "
              placeholder="Skill.."
              onChange={(e) => {
                setEdited({ ...edited!, skills: e as string[] });
              }}
              selected={edited?.skills ?? []}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Referens</Form.Label>
            <Form.Control
              placeholder=""
              value={edited?.referencePersonName ?? ""}
              onChange={(e) => {
                setEdited({ ...edited!, referencePersonName: e.target.value });
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Referens epost</Form.Label>
            <InputGroup>
            <Form.Control
              placeholder=""
              value={edited?.referencePersonEmail ?? ""}
              onChange={(e) => {
                setEdited({ ...edited!, referencePersonEmail: e.target.value });
              }}
            ></Form.Control>
              <Button href={"mailto:" + edited?.referencePersonEmail} target="#" disabled={!edited?.referencePersonEmail}><i className="bi bi-envelope"></i></Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Referens telefon</Form.Label>
            <InputGroup>
            <Form.Control
              placeholder=""
              value={edited?.referencePersonPhone ?? ""}
              onChange={(e) => {
                setEdited({ ...edited!, referencePersonPhone: e.target.value });
              }}
            ></Form.Control>
              <Button href={"tel:" + edited?.referencePersonPhone}  target="#"><i className="bi bi-telephone-outbound"></i></Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Form.Label>Referenstagning, status</Form.Label>
            <ProcessSteps steps={[
              {id:"1", title: "Referens angiven", completed: true, description: "Kandidaten har angivit en referensperson"},
              {id:"2", title: "Inbjudan skickad", completed: true, description: "Inbjudan har skickats till referenspersonen"},
              {id:"3", title: "Klar", completed: false, description: "Inbjudan har skickats till referenspersonen"},
            ]}></ProcessSteps>
          </FormGroup>
        </Col>
      </Form>
      {errorMessage && (
        <Alert variant="danger" className="mt-2">
          {errorMessage}
        </Alert>
      )}

      <div className="mt-2 gm-2 d-flex justify-content-end">
        <Button onClick={reload}>Ångra</Button>
        <Button className="ms-2" onClick={save}>
          Spara
        </Button>
      </div>
  
      <Modal show={showCvViewer} animation size="lg" onHide={()=>setShowCvViewer(false)} scrollable backdrop>
        <ModalHeader closeButton>CV</ModalHeader>
        <ModalBody >
        <Document
            file={"/api/file/file/" + edited?.cvFileId}
            className="pdf_document ms-auto me-auto" 
            
          >
            <Page
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              
            />
          </Document>
          
        </ModalBody>
      </Modal>
    </>
  );
}
