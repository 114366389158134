import './Home.css';
import React, { useEffect, useState } from 'react'
import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, Image, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DashboardSection from '../components/DashboardSection';
import { title } from 'process';
import { useAuth } from 'react-oidc-context';
import CandidatesService from '../services/CandidateService';
import { Candidate } from '../types/candidate';
import moment from 'moment';
import CompanyService from '../services/CompanyService';
import { Company } from '../types/company';
import ProjectService from '../services/ProjectService';
import { Project } from '../types/project';
import { toAgeString } from '../utils';
import Timestamp from '../components/Timestamp';

export const Home = () => {
    const auth = useAuth();
    const [candidates, setCandidates] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [projects, setProjects] = useState([]);

    const reloadCompanies = () => {
        CompanyService.getCompanies(auth).then((response) => {
          console.log("Response: ", response);
          setCompanies(response.data.slice(0, 10));
        });
      }
    const reloadCandidates = () => {
        CandidatesService.getCandidates(auth).then((response) => {
          console.log("Response: ", response);
          setCandidates(response.data.slice(0, 10));
        });
      };
      const reloadProjects = () => {
        ProjectService.getProjects(auth).then((response) => {
          console.log("Response: ", response);
          setProjects(response.data);
        });
      };
      useEffect(() => {
        reloadCandidates();
        reloadCompanies();
        reloadProjects();
      }, []);
    
    return (
        <Container fluid="lg" className="pt-3">
            <Row xs={1} sm={1} md={2} lg={2} xl={2} className="g-6 mt-4">
                <Col>
                    <DashboardSection title='Projekt'>
                            <Table hover size='sm' className='table-transparent' responsive='sm'>
                                {/*
                                <thead>
                                    <tr>
                                        <th>Company</th>
                                        <th >Title</th>
                                        <th>Updated</th>
                                        </tr>
                                    </thead>
                                    */}
                                <tbody>
                                {projects.map((project: Project, index) => {
                                    return (
                                        <tr key={project.id}>
                                            <td><Link to={"/companies/" + project.companyId} className='link-primary'>{project.companyName}</Link></td>
                                            <td><Link to={"/project/" + project.id} className='link-success'>{project.title}</Link></td>
                                            <td>{project.updated && <Timestamp timestamp={project.updated}/>}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                         </DashboardSection>
                    </Col>
                    <Col>
                   <DashboardSection title='Kandidater'>
                            <Table responsive='sm' hover size='sm' className='table-transparent'>
                                <tbody>
                                    {candidates.map((candidate: Candidate, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td><Image roundedCircle src={candidate.avatarId ? ("/api/file/file/" + candidate.avatarId) : '/projectfiles/avatar_anonymous.png'} style={{height: '32px',width: '32px',objectFit: 'cover',transform: 'none'}}/></td>
                                                <td><Link to="/candidates" className='link-primary'>{candidate.name}</Link></td>
                                                <td>{candidate.projects && candidate.projects.length > 0 && <Link to={"project/" + candidate.projects[0].id} className='link-secondary'>{candidate.projects[0].projectInfoHub?.projectTitle ?? "-"}</Link>}</td>
                                                <td>{candidate.updated && <Timestamp timestamp={candidate.updated}/>}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            < Card.Link href="#" className='btn btn-primary btn-sm'>Ny kandidat ..</Card.Link>
                       </DashboardSection>
                    
                </Col>
                <Col>
                    <DashboardSection title='Kunder'>
                            <Table hover className='table-transparent' size='sm' responsive='sm'>
                                <tbody >
                                    {companies.map((company: Company, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{textAlign: 'center',maxWidth: '65px'}}><img src={"/api/file/file/" + company.avatarId} style={{maxHeight: '32px', maxWidth: '60px'}}></img></td>
                                                <td><Link to={"/companies/" + company.id} className='link-primary'>{company.name}</Link></td>
                                                <td>{company.updated && <Timestamp timestamp={company.updated}/>}</td>

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            < Card.Link href="#" className='btn btn-primary btn-sm'>Ny Kund</Card.Link>
                       </DashboardSection>
                </Col>
            </Row>
        </Container>
    )
}
