import React from 'react'
import { toAgeString } from '../utils'

export default function Timestamp({timestamp}:{timestamp:Date}) {
    const dateStr = toAgeString(timestamp);
    if (dateStr === "now") {
        return <span className='badge text-bg-warning'>now</span>
    }
    else return <span>{dateStr}</span>
}
