import React, { useEffect } from 'react'
import { CandidateInfoHub, InfoHub, InfoNode, Project } from '../../types/project';
import Dropzone from 'react-dropzone';
import InfoNodeEditor from './InfoNodeEditor';
import { useAuth } from 'react-oidc-context';
import FileUploadService from '../../services/FileUploadService';
import './CandidateInfoHubEditor.css';
import ProjectService from '../../services/ProjectService';

export default function CandidateInfoHubEditor({candidateInfoHubId, project, className, infoHubChanged} : {candidateInfoHubId: string, project: Project, className?: string, infoHubChanged: (infoHub: InfoHub) => void  }) {
  const MISSING_IMAGE = '/projectfiles/avatar_anonymous.png';
  const auth = useAuth();
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [candidateInfoHub, setCandidateInfoHub] = React.useState<CandidateInfoHub>();
  const [imageSrc, setImageSrc] = React.useState<string>(candidateInfoHub?.imageId ? "/api/file/file/" + candidateInfoHub.imageId : MISSING_IMAGE);
  useEffect(() => {
    if (candidateInfoHubId) {
      reload();
    } 
  }, [candidateInfoHubId]);

  const reload = () => {  
    console.log("Reloading candidateInfoHub. Id: ", candidateInfoHubId);
    ProjectService.getCandidateInfoHub(auth, candidateInfoHubId).then((response) => {
      console.log("Got projectInfoHub: ", response.data);
      setCandidateInfoHub(response.data);
      setImageSrc(response.data.imageId ? "/api/file/file/" + response.data.imageId : MISSING_IMAGE);
    }).catch((error) => {
      alert("Error getting projectInfoHub: " + error);
    });
  }
  const onDropAccepted = (acceptedFiles: File[]) => {
    console.log("Accepted files: ", acceptedFiles);
    setImageSrc(MISSING_IMAGE);
    setShowLoading(true);
    FileUploadService.upload(auth, acceptedFiles[0], "image", null).then((response) => {
      setShowLoading(false);
      if (response.status !== 200 && response.data?.length === 1) {
        alert("Error uploading file");
      }
      else {
        const imageId = response.data[0].id;
        console.log("Setting imageId: ", imageId);
        candidateInfoHub!.imageId = imageId;
        ProjectService.patchCandidateInfoHub(auth, candidateInfoHubId, {imageId: imageId}).then((response) => {
          infoHubChanged(response.data);
          console.log("Updated candidateInfoHub: ", response.data);
        }).catch((error) => { alert("Error updating candidateInfoHub: " + error); });
        setImageSrc("/api/file/file/" + imageId);
      }

    })

  }
  const handleInfoNodechanged = (infoNode: InfoNode) => {  
      
  }


  return (
    <div className={className}>
      <h3 className="text-primary"><i className="bi bi-person"></i>{candidateInfoHub?.candidateName}</h3>
      <Dropzone onDropAccepted={onDropAccepted}>
        {({getRootProps, getInputProps}) => (
          <section >
            <div {...getRootProps()} style={{border: '1px dotted darkgray',display: 'inline-flex', position: 'relative',borderRadius: '8px', padding: '4px'}}>
              <input {...getInputProps()} />
              <img src={imageSrc} className="rounded avatar_img"/>
              {showLoading && <div className="uploading-text">Uploading..</div>}
            </div>
          </section>
        )}
      </Dropzone>
      
      {candidateInfoHub?.infoNodes?.map((infoNode, index) => {
        return (<InfoNodeEditor className="mt-2" infoNodeId={infoNode.id as string} project={project} key={index} infoNodeChanged={handleInfoNodechanged}/>);
      })}
    </div>
  )
}
