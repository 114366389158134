import { AgGridReact } from "ag-grid-react";
import { ColDef, IRowNode, ICellRendererParams,RowDoubleClickedEvent } from "ag-grid-community";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Company } from "../types/company";
import { RowSelectedEvent } from "ag-grid-community";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Form,
  Modal,
} from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import CompanyService from "../services/CompanyService";
import { TagsRenderer } from "../components/table/TagsRenderer";
import { DateAgeRenderer } from "../components/table/DateAgeRenderer";
import { AvatarRenderer } from "../components/table/AvatarRenderer";
import { LogoRenderer } from "../components/table/LogoRenderer";
import CompanyEditor from "../components/company/CompanyEditor";

export const Companies = () => {
  const { id } = useParams();
  const auth = useAuth();
  const gridApi = useRef<AgGridReact<Company>>(null);
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);
  const [showCompany, setShowCompany] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const isExternalFilterPresent = useCallback((): boolean => {
    return true;
  }, []);
  const doesExternalFilterPass = useCallback(
    (node: IRowNode<Company>): boolean => {
      if (node.data && searchFilter !== "") {
        console.log("searchFilter: ", searchFilter);
        const regexp = new RegExp(searchFilter, "i");
        if (node.data.name && regexp.test(node.data.name)) {
          return true;
        }
        if (node.data.about && regexp.test(node.data.about)) {
          return true;
        }
        if (node.data.tags && regexp.test(node.data.tags.join())) {
          return true;
        }
        return false;
      } else return true;
    },
    [searchFilter]
  );
  const [columnDefs, setColumnDefs] = useState<ColDef<Company, any>[]>([
    {
      field: "avatarId",
      cellRenderer: LogoRenderer,
      headerName: "",
      maxWidth: 65,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Namn",
      sortable: true,
      filter: true,
      maxWidth: 200,
    },
    { field: "about", headerName: "Om", sortable: true, filter: true },
    {
      field: "tags",
      headerName: "Taggar",
      cellRenderer: TagsRenderer,
      maxWidth: 200,
    },
    {
      field: "created",
      headerName: "Skapat",
      cellRenderer: DateAgeRenderer,
      maxWidth: 150,
    },
    {
      field: "updated",
      headerName: "Uppdaterat",
      cellRenderer: DateAgeRenderer,
      sort: "desc",
      maxWidth: 150,
    },
    {
      maxWidth: 100,
      cellRenderer: (params:ICellRendererParams<Company>) => {
        return (
          <div>
          <Button
            size="sm"
            title="Edit project in mindmap mode"
            variant="link"

            onClick={() => {
              deleteCompany(params.data!);
            }}
          >
            <i className='bi bi-trash'></i>
          </Button>
          <Button
          size="sm"
          variant="link"
          title="Quick edit"
          
          onClick={() => {
            setSelectedCompany(params.data);
            setShowCompany(true);
          }}
        >
          <i className='bi bi-pencil-square'></i>
        </Button>
        </div>
        );
      }
    }
  ]);

  const [companies, setCompanies] = useState<Company[]>([]);
  const [showNewCompany, setShowNewCompany] = useState(false);
  const onRowSelected = useCallback((event: RowSelectedEvent<Company>) => {
    console.log("onRowSelected", event);
  }, []);
  function handleShowNewCompanyModal(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    throw new Error("Function not implemented.");
  }
  function onRowDoubleClicked(event: RowDoubleClickedEvent<Company>) {
    setSelectedCompany(event.data);
    setShowCompany(true);
  }

  function searchFilterChanged(event: any): void {
    gridApi.current!.api.onFilterChanged();
    const searchTerm = event?.target?.value;
    setSearchFilter(searchTerm);
    console.log("searchFilter: ", searchFilter);
  }
  function deleteCompany(company: Company) {
    CompanyService.deleteCompany(auth, company.id).then((response) => {
      console.log("Response: ", response);
      reloadCompanies();
    });
  }
  const reloadCompanies = () => {
    CompanyService.getCompanies(auth).then((response) => {
      console.log("Response: ", response);
      setCompanies(response.data);
    });
  };
  useEffect(() => {
    reloadCompanies();
  }, []);
  return (
    <>
      <Modal
        show={showNewCompany}
        onHide={() => setShowNewCompany(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ny Kund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompanyEditor id="" onClose={() => {setShowNewCompany(false);reloadCompanies();}} />
        </Modal.Body>
      </Modal>
      <Modal
          size="xl"
          show={showCompany}
          onHide={() => {setShowCompany(false);reloadCompanies();}}
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedCompany?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <CompanyEditor id={selectedCompany?.id!} onClose={() => {setShowCompany(false);reloadCompanies();}} />
          </Modal.Body>
        </Modal>

      <Container className="pt-3">
        <Form>
          <Row className="mt-2">
            <Col>
              <Form.Control placeholder="Sök" />
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  onClick={() => setShowNewCompany(true)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Nytt projekt"
                >
                  <i className="bi-plus-lg"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col>
            <div
              className="ag-theme-quartz mt-2" // applying the grid theme
              style={{ height: 500 }} // the grid will fill the size of the parent container
            >
              <AgGridReact<Company>
                getRowId={(data) => data.data.id}
                rowSelection="multiple"
                autoSizeStrategy={{
                  type: "fitGridWidth",
                  defaultMinWidth: 100,
                }}
                ref={gridApi}
                rowData={companies}
                onRowSelected={onRowSelected}
                columnDefs={columnDefs}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
