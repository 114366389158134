import axios from "axios";
import { AuthContextProps, useAuth } from "react-oidc-context";

const upload = (auth: AuthContextProps, file: File, fileType: string, onUploadProgress: any): Promise<any> => {
    let formData = new FormData();

    formData.append("files", file);
    formData.append("fileType", fileType);

    return axios.post("/api/file/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer ' + auth.user?.access_token
        },
        onUploadProgress,
    });
};

const getFiles = (auth: AuthContextProps): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    return axios.get("/api/file/files", config);
};

const FileUploadService = {
    upload,
    getFiles,
};

export default FileUploadService;