import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

export class EmailRenderer implements ICellRendererComp {
    eGui!: HTMLSpanElement;
  
    init(params: ICellRendererParams) {
      this.eGui = document.createElement('div');
      if (params.value) {
        let html = "<a href='mailto:" + params.value + "'>" + params.value + "</a>";
        this.eGui.innerHTML = html;
      }
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
  }