import axios from "axios";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { Candidate } from "../types/candidate";
import { arrayEquals } from "../utils";

const getCandidates = (auth: AuthContextProps): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    return axios.get("/api/candidate/candidate", config);
};
const getCandidate = (auth: AuthContextProps, id: string): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    return axios.get("/api/candidate/candidate/" + id, config);
};
const deleteCandidate = (auth: AuthContextProps, id: string): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    return axios.delete("/api/candidate/candidate/" + id, config);
};

const patchCandidate = (auth: AuthContextProps, original: Candidate, edited: Candidate): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    const patch:any = {};
    // TODO: Can this be made smarter?
    if (original.avatarId !== edited.avatarId) {patch["avatarId"] = edited.avatarId;}
    if (original.name !== edited.name) {patch["name"] = edited.name;}
    if (original.summary !== edited.summary) {patch["summary"] = edited.summary;}
    if (original.email !== edited.email) {patch["email"] = edited.email;}
    if (original.linkedInUrl !== edited.linkedInUrl) {patch["linkedInUrl"] = edited.linkedInUrl;}
    if (original.expectedSalary !== edited.expectedSalary) {patch["expectedSalary"] = edited.expectedSalary;}
    if (original.noticePeriod !== edited.noticePeriod) {patch["noticePeriod"] = edited.noticePeriod;}
    if (original.phoneNumber !== edited.phoneNumber) {patch["phoneNumber"] = edited.phoneNumber;}
    if (original.referencePersonEmail !== edited.referencePersonEmail) {patch["referencePersonEmail"] = edited.referencePersonEmail;}
    if (original.referencePersonName !== edited.referencePersonName) {patch["referencePersonName"] = edited.referencePersonName;}
    if (original.referencePersonPhone !== edited.referencePersonPhone) {patch["referencePersonPhone"] = edited.referencePersonPhone;}
    if (original.rating !== edited.rating) {patch["rating"] = edited.rating;}
    if (!arrayEquals(original.tags, edited.tags)) {
        patch["tags"] = edited.tags.map((t) => {return t.name ?? t;});
    }
    if (!arrayEquals(original.skills, edited.skills)) {
        patch["skills"] = edited.skills.map((t) => {return t.name ?? t;});
    }
    console.log("Patch: ", patch);

    return axios.patch("/api/candidate/candidate/" + original.id, patch, config);
};

const postCandidate = (auth: AuthContextProps, c: Candidate): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    
    return axios.post("/api/candidate/candidate", c, config);
};


const CandidatesService = {
    getCandidates,getCandidate, patchCandidate, postCandidate, deleteCandidate
};

export default CandidatesService;

