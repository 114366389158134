export class PinchPanHandler {
	private targetElement: HTMLElement;
	private containerBoundingBox: DOMRect;
	private smallestContainerDimension: number;
	private eventCache: PointerEvent[] = [];
	
	private previousPanX: number = 0;
	private previousPanY: number = 0;
	private previousPinchGap: number = 0;
	private currentX: number;
	private currentY: number;
	private currentWidthHeight: number;

	private moveStepFactor = .25;
	private zoomStepFactor = .75;
	
	constructor(target: HTMLElement, container: HTMLElement) {
		this.targetElement = target;
		this.containerBoundingBox = container.getBoundingClientRect();

		target.style.position = "absolute";
		target.style.cursor = "grab";
		target.style.userSelect = "none";

		target.addEventListener("dblclick", e => {
			e.preventDefault();
			this.zoom(1, e.offsetX, e.offsetY)
		});

		target.addEventListener("wheel", e => {
			e.preventDefault();
			this.zoom(-e.deltaY * .01, e.offsetX, e.offsetY, false);
		});

		this.smallestContainerDimension = Math.min(this.containerBoundingBox.width, this.containerBoundingBox.height);

		this.currentX = (this.containerBoundingBox.width - this.smallestContainerDimension) / 2;
		this.currentY = (this.containerBoundingBox.height - this.smallestContainerDimension) / 2;
		this.currentWidthHeight = this.smallestContainerDimension;

		new ResizeObserver(entries => {
			this.containerBoundingBox = entries[0].contentRect;
			this.smallestContainerDimension = Math.min(this.containerBoundingBox.width, this.containerBoundingBox.height);
			this.ensureBoundsAndUpdateUi();
		}).observe(container);
		
		this.ensureBoundsAndUpdateUi();

		target.onpointerdown = this.pointerDown.bind(this);
		target.onpointermove = this.pointerMove.bind(this);
		target.onpointerup = this.pointerUp.bind(this);
		target.onpointercancel = this.pointerUp.bind(this);
		// target.onpointerout = this.pointerUp.bind(this);
		target.onpointerleave = this.pointerUp.bind(this);
	}

	public move(deltaX: number, deltaY: number) {
		let scale = this.containerBoundingBox.width * this.moveStepFactor;

		this.currentX += deltaX * scale;
		this.currentY += deltaY * scale;

		this.targetElement.style.transitionDuration = ".25s";
		this.ensureBoundsAndUpdateUi();
	}

	public zoom(zoom: number, originX?: number, originY?: number, smooth: boolean = true) {
		let scale = this.smallestContainerDimension * this.zoomStepFactor;
		let deltaWidthHeight = Math.max(zoom * scale, this.smallestContainerDimension - this.currentWidthHeight);

		if (originX !== undefined && originY !== undefined) {
			this.currentX -= deltaWidthHeight * (originX / this.currentWidthHeight);
			this.currentY -= deltaWidthHeight * (originY / this.currentWidthHeight);
		}
		else {
			this.currentX -= deltaWidthHeight / 2;
			this.currentY -= deltaWidthHeight / 2;
		}

		this.currentWidthHeight += deltaWidthHeight;

		if (smooth)
			this.targetElement.style.transitionDuration = ".25s";

		this.ensureBoundsAndUpdateUi();
		
	}

	private pointerDown(e: PointerEvent) {
		this.eventCache.push(e);
		this.pointerCountChange();
	}
	
	private pointerUp(e: PointerEvent) {
		let index = this.eventCache.findIndex(x => x.pointerId === e.pointerId);

		if (index !== -1) {
			this.eventCache.splice(index, 1);
			this.pointerCountChange();
		}
	}
	
	private pointerMove(e: PointerEvent) {
		let index = this.eventCache.findIndex(x => x.pointerId === e.pointerId);

		if (index !== -1)
			this.eventCache[index] = e;

		if (this.eventCache.length >= 1) {
			this.targetElement.style.transitionDuration = "";

			let currentPointerX = this.eventCache[0].clientX;
			let currentPointerY = this.eventCache[0].clientY;

			this.currentX += currentPointerX - this.previousPanX;
			this.currentY += currentPointerY - this.previousPanY;

			if (this.eventCache.length === 2) {
				let currentPinchGap = this.getDistance(currentPointerX, currentPointerY, this.eventCache[1].clientX, this.eventCache[1].clientY);
				let newWidthHeight = this.currentWidthHeight * (currentPinchGap / this.previousPinchGap);
				let deltaWidthHeight = Math.max(newWidthHeight - this.currentWidthHeight, this.smallestContainerDimension - this.currentWidthHeight);

				this.currentX -= deltaWidthHeight * (this.eventCache[0].offsetX / this.currentWidthHeight);
				this.currentY -= deltaWidthHeight * (this.eventCache[0].offsetY / this.currentWidthHeight);
				this.currentWidthHeight = newWidthHeight;
				this.previousPinchGap = currentPinchGap;
			}

			this.ensureBoundsAndUpdateUi();

			this.previousPanX = currentPointerX;
			this.previousPanY = currentPointerY;
		}
	}

	private pointerCountChange() {
		if (this.eventCache.length > 0) {
			this.previousPanX = this.eventCache[0].clientX;
			this.previousPanY = this.eventCache[0].clientY;

			if (this.eventCache.length === 2)
				this.previousPinchGap = this.getDistance(this.eventCache[0].clientX, this.eventCache[0].clientY, this.eventCache[1].clientX, this.eventCache[1].clientY);
		}
	}

	private ensureBoundsAndUpdateUi() {
		this.currentWidthHeight = Math.max(this.currentWidthHeight, Math.min(this.containerBoundingBox.width, this.containerBoundingBox.height));

		if (this.containerBoundingBox.width < this.currentWidthHeight)
			this.currentX = Math.max(Math.min(this.currentX, 0), this.containerBoundingBox.width - this.currentWidthHeight);
		else
			this.currentX = Math.min(Math.max(this.currentX, 0), this.containerBoundingBox.width - this.currentWidthHeight);

		if (this.containerBoundingBox.height < this.currentWidthHeight)
			this.currentY = Math.max(Math.min(this.currentY, 0), this.containerBoundingBox.height - this.currentWidthHeight);
		else
			this.currentY = Math.min(Math.max(this.currentY, 0), this.containerBoundingBox.height - this.currentWidthHeight);

		this.targetElement.style.left = this.currentX + "px";
		this.targetElement.style.top = this.currentY + "px";
		this.targetElement.style.width = this.currentWidthHeight + "px";
		this.targetElement.style.height = this.currentWidthHeight + "px";
	}

	private getDistance(x1: number, y1: number, x2: number, y2: number): number {
		return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
	}
}