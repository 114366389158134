import React, { useState } from "react";
import { CandidateInfoHub, InfoNode } from "../../types/project";
import {HUB_WIDTH, NODE_DISTANCE, HUB_OFFSET} from './SvgConstants';

export default function SvgInfoNode({infoNode, dindex, selectInfoNode}:{infoNode: InfoNode, dindex: number, selectInfoNode: (infoNode: InfoNode) => void}) {

  return (
    <g
      onClick={() => {
        selectInfoNode(infoNode);
      }}
      key={"doc_pih" + dindex}
    >
      <circle
        key={"doc2_pih" + "_" + dindex}
        cx={
          HUB_WIDTH / 2 +
          NODE_DISTANCE * Math.cos(-Math.PI / 12 + (Math.PI / 7) * dindex)
        }
        cy={
          HUB_WIDTH / 2 -
          HUB_OFFSET +
          NODE_DISTANCE * Math.sin(-Math.PI / 12 + (Math.PI / 7) * dindex)
        }
        r="20"
        className="c_menu_item"
      ></circle>
      <text
        key={"doc3_pih" + "_" + dindex}
        className="c_menu_text"
        x={
          HUB_WIDTH / 2 +
          NODE_DISTANCE * Math.cos(-Math.PI / 12 + (Math.PI / 7) * dindex) +
          28 * Math.cos(-Math.PI / 12 + (Math.PI / 7) * dindex)
        }
        y={
          HUB_WIDTH / 2 -
          HUB_OFFSET +
          NODE_DISTANCE * Math.sin(-Math.PI / 12 + (Math.PI / 7) * dindex) +
          28 * Math.sin(-Math.PI / 12 + (Math.PI / 7) * dindex) +
          6
        }
      >
        {infoNode.title}
      </text>
    </g>
  );
}
