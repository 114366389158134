import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './custom.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from 'react-oidc-context';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { WebStorageStateStore } from 'oidc-client-ts';

// Reading deepLinkPath from localStorage. See index.html
const deepLinkPath = localStorage.getItem("deepLinkPath");
console.log("DeepLinkPath from local storage: ", deepLinkPath);
let redirect_uri = window.location.origin;
if (deepLinkPath && deepLinkPath.indexOf("/project/") >= 0) {
  console.log("Found deepLinkPath in localStorage: ", deepLinkPath);
  redirect_uri = redirect_uri + deepLinkPath;
  console.log("New redirect_uri: ", redirect_uri);
  localStorage.removeItem("deepLinkPath");
}
// OIDC: See https://github.com/authts/react-oidc-context?tab=readme-ov-file#installation
const oidcConfig = {
  authority: (window as any).env.auth_authority,
  realm: (window as any).env.auth_realm,
  client_id: (window as any).env.auth_client_id,
  redirect_uri: redirect_uri,
  scope: "openid profile email offline_access",
  response_type: "code",
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};
console.log("OICD Config: ", oidcConfig);

console.log("Windows: ", window.location);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
    <App/>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
