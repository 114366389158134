import React from 'react'
import {HUB_WIDTH, NODE_DISTANCE, HUB_OFFSET, CIRCLE_RADIUS} from './SvgConstants';
import { CandidateInfoHub, InfoNode } from '../../types/project';
import SvgInfoNode from './SvgInfoNode';

export default function SvgCandidateInfoHub({candidateInfoHub, index, candidateHubCount, selectCandidateInfoHub, selectInfoNode}:{candidateInfoHub: CandidateInfoHub, index: number, candidateHubCount: number, selectCandidateInfoHub: (c: CandidateInfoHub) => void, selectInfoNode: (infoNode: InfoNode) => void}) {
  return (
    <svg
        x={20 + ((1000 - 2*20)/candidateHubCount) * index}
        y={400}
        width={HUB_WIDTH}
        height={HUB_WIDTH}
      >
        <g
          onClick={() => selectCandidateInfoHub(candidateInfoHub)}
        >
          <text
            x={HUB_WIDTH/2}
            width="0"
            y={HUB_WIDTH/2 - HUB_OFFSET - CIRCLE_RADIUS - 40}
            className="c_image_title"
            alignmentBaseline="middle"
            textAnchor="middle"
          >
            {candidateInfoHub.candidateName}{" "}
          </text>
          <text
            x={HUB_WIDTH/2}
            width="0"
            y={HUB_WIDTH/2 - HUB_OFFSET - CIRCLE_RADIUS - 20}
            className="c_image_tagline"
            alignmentBaseline="middle"
            textAnchor="middle"
          >
            {candidateInfoHub.candidatetagLine}{" "}
          </text>
         <image
            href={'/api/file/file/' + candidateInfoHub.imageId}
            height={CIRCLE_RADIUS * 2}
            width={CIRCLE_RADIUS * 2}
            x={HUB_WIDTH/2 - CIRCLE_RADIUS}
            y={HUB_WIDTH/2 - CIRCLE_RADIUS - HUB_OFFSET}
            className="c_image"
          ></image>
          <circle
            cx={HUB_WIDTH/2}
            cy={HUB_WIDTH/2 - HUB_OFFSET}
            r={CIRCLE_RADIUS}
            className="c_border"
          ></circle>
        </g>
        {candidateInfoHub.infoNodes?.map((d:InfoNode, dindex) => {
          return (
           <SvgInfoNode infoNode={d} dindex={dindex} selectInfoNode={selectInfoNode} key={dindex}/>
          );
        })}
      </svg>       
  )
}
