import { FormField, InfoNode, Project } from "../../types/project";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import type { EventInfo } from "@ckeditor/ckeditor5-utils";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormFieldEditor from "./FormFieldEditor";
import { Button, Form, Figure, Collapse } from "react-bootstrap";
import { FileInfo, LinkInfo } from "../../types/common";
import "./InfoNodeEditor.css";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import ProjectService from "../../services/ProjectService";
import { useAuth } from "react-oidc-context";
import Dropzone from "react-dropzone";
import FileUploadService from "../../services/FileUploadService";


export default function InfoNodeEditor({
  infoNodeId,
  project,
  className,
  infoNodeChanged,
}: {
  infoNodeId: string;
  project: Project;
  className?: string;
  infoNodeChanged: (infoNode: InfoNode) => void;
}) {
  const MISSING_IMAGE = "/projectfiles/image-missing.svg";

  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [original, setOriginal] = useState<InfoNode>();
  const [edited, setEdited] = useState<InfoNode>();

  // Add media files. TODO: Move to separate comonent? Dialog?
  const [showUploading, setShowUploading] = useState<boolean>(false);
  const [uploadImageSrc, setUploadImageSrc] = useState<string>(MISSING_IMAGE);
  const [showDropZone, setShowDropZone] = useState<boolean>(false);

  const onDropAccepted = (acceptedFiles: File[]) => {
    console.log("Accepted files: ", acceptedFiles);
    setUploadImageSrc(MISSING_IMAGE);
    setShowUploading(true);
    FileUploadService.upload(auth, acceptedFiles[0], "image", null).then(
      (response) => {
        setShowUploading(false);
        if (response.status !== 200 && response.data?.length === 1) {
          alert("Error uploading file");
        } else {
          const fileInfo = response.data[0];
          const patch = { addFile: fileInfo };
          ProjectService.patchInfoNode(auth, infoNodeId, patch)
            .then((response) => {
              console.log("Updated projectInfoHub: ", response.data);
              reload();
              setShowDropZone(false);
            })
            .catch((error) => {
              alert("Error updating projectInfoHub: " + error);
            });
        }
      }
    );
  };
  const removeFile = (fileId: string) => {
    ProjectService.deleteInfoNodeFile(auth, infoNodeId, fileId)
      .then((response) => {
        console.log("Deleted file: ", response.data);
        reload();
      })
      .catch((error) => {
        alert("Error deleting file: " + error);
      });
  };

  useEffect(() => {
    console.log("useEffect. infoNodeId: ", infoNodeId);

    if (!loading) {
      reload();
    }
  }, [infoNodeId]);
  useEffect(() => { 
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  },[]);
  const reload = () => {
    if (infoNodeId) {
      console.log("Reloading. infoNodeId: ", infoNodeId);
      setLoading(true);
      setEdited(undefined);
      setOriginal(undefined);
      ProjectService.getInfoNode(auth!, infoNodeId)
        .then((response) => {
          console.log("Got infoNode: ", response.data);
          setEdited(response.data);
          setOriginal(response.data);
          setLoading(false);
        })
        .catch((error) => {
          alert("Error getting infoNode: " + error);
          setLoading(false);
        });
    }
  };
  const doMagic = () => {
    if (!edited) return;
    edited.text =
      "<center><img src='/projectfiles/spinner.gif' width=200/></center>";
    infoNodeChanged(edited);
    setLoading(true);
    ProjectService.suggestRichTextContent(auth!, edited.suggestAction!, project)
      .then((response) => {
        console.log("Suggested formal requirements: ", response.data);
        edited.text = response.data;
        infoNodeChanged(edited);
        setLoading(false);
      })
      .catch((error) => {
        alert("Error getting suggested content: " + error);
        setLoading(false);
      });
  };
  const save = () => {
    if (!edited) return;
    ProjectService.patchInfoNode(auth!, edited.id as string, {
      text: edited.text,
    })
      .then((response) => {
        console.log("Updated infoNode: ", response.data);
      })
      .catch((error) => {
        alert("Error updating infoNode: " + error);
      });
  };

  if (!edited) {
    return <div>Loading...</div>;
  }
  switch (edited.type) {
    case "RichText":
      return (
        <>
          <Form.Group
            className={`small-editor-container ${className}`}
            controlId="req.required_formal_competences"
          >
            <Form.Label>
              {edited.title}{" "}
              {edited.suggestAction && (
                <Button variant="link" style={{ padding: 0 }}>
                  <i className="bi bi-magic" onClick={doMagic}></i>
                </Button>
              )}
            </Form.Label>
            {/*A hack to fix the foreground color of the editor*/}
            <div style={{color: '#333'}}>
            <CKEditor
              disabled={loading}
              editor={ClassicEditor}
              data={original?.text}
              onChange={(event: EventInfo, editor: ClassicEditor) => {
                setEdited({ ...edited, text: editor.getData() });
              }}
            />
            </div>
          </Form.Group>
          <Button disabled={edited.text === original?.text} onClick={save} className="mt-2">
            Save
          </Button>
        </>
      );
    case "Form":
      return (
        <div className={className}>
          <h4 className="text-primary">{edited.title}</h4>
          {edited.formFields?.map((formField: FormField, i) => {
            return <FormFieldEditor formField={formField} key={i} />;
          })}
        </div>
      );
    case "Media":
      return (
        <div className={className}>
          <div className="d-flex mb-2">
            <h4 className="text-primary flex-grow-1">
              <i className="bi bi-paperclip"></i> {edited.title}
            </h4>
            <Button variant="primary" onClick={() => setShowDropZone(!showDropZone)}>
              <i className="bi bi-plus"></i>
            </Button>
          </div>
          <div hidden={!showDropZone} className="mt-2 mb-2" >
            <Dropzone onDropAccepted={onDropAccepted}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps()}
                    style={{
                      border: "1px dotted darkgray",
                      display: "inline-flex",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "4px",
                    }}
                  >
                    <input {...getInputProps()} />
                    <img src={uploadImageSrc} className="rounded avatar_img" />
                    {showUploading && (
                      <div className="uploading-text">Uploading..</div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="files-container">
            {edited.files?.map((file: FileInfo, i) => {
              if (file.contentType.includes("image")) {
                return (
                  <Figure key={i}>
                    <Figure.Image
                      src={"/api/file/file/" + file.id}
                      rounded
                      className="file-media-image me-4"
                    />
                    <Figure.Caption>
                      {file.fileName}&nbsp;
                      <a
                        href={"/api/file/file/" + file.id}
                        download={file.fileName}
                      >
                        <i className="bi bi-download"></i>
                      </a>
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => {
                          removeFile(file.id);
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                    </Figure.Caption>
                  </Figure>
                );
              }
              if (file.contentType.includes("application/pdf")) {
                return (
                  <div key={i}>
                    <Document
                      file={"/api/file/file/" + file.id}
                      className="pdf_document"
                    >
                      <Page
                        pageNumber={1}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        width={550}
                      />
                    </Document>
                    <a
                      href={"/api/file/file/" + file.id}
                      download={file.fileName}
                    >
                      <i className="bi bi-download"></i>
                    </a>
                    <Button
                      variant="link"
                      size="sm"
                      onClick={() => {
                        removeFile(file.id);
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </div>
                );
              } else
                return <div key={i}>Unknown file type: {file.contentType}</div>;
            })}
          </div>
          <br />
          {edited.links?.map((link: LinkInfo, i) => {
            return (
              <a href={link.url} target="_blank">
                {link.title} <i className="bi bi-box-arrow-up-right"></i>
              </a>
            );
          })}
        </div>
      );
    default:
      return (
        <div className={className}>Unknown InfoNode type: {edited.type}</div>
      );
  }
}
