import axios, { AxiosRequestConfig } from "axios";
import { AuthContextProps } from "react-oidc-context";
import { CreateProjectAction } from "../types/project";
import { Company } from "../types/company";

const getCompanies = (auth: AuthContextProps): Promise<any> => {
    return axios.get("/api/company/company", defaultConfig(auth));
};

const getCompany = (auth: AuthContextProps, id: string): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    return axios.get("/api/company/company/" + id, config);
};

const deleteCompany = (auth: AuthContextProps, id: string): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    return axios.delete("/api/company/company/" + id, config);
};

function arrayEquals(a:string[], b:string[]) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
} 
const patchCompany = (auth: AuthContextProps, original: Company, edited: Company): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    const patch:any = {};
    // TODO: Can this be made smarter?
    if (original.avatarId !== edited.avatarId) {patch["avatarId"] = edited.avatarId;}
    if (original.name !== edited.name) {patch["name"] = edited.name;}
    if (original.about !== edited.about) {patch["about"] = edited.about;}
    
    if (!arrayEquals(original.tags, edited.tags)) {
        patch["tags"] = edited.tags.map((t) => {return t.name ?? t;});
    }
    console.log("Patch: ", patch);

    return axios.patch("/api/company/company/" + original.id, patch, config);
};

const postCompany = (auth: AuthContextProps, c: Company): Promise<any> => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        }
    };
    
    return axios.post("/api/company/company", c, config);
};


const defaultConfig = (auth: AuthContextProps): AxiosRequestConfig => {
    const config: AxiosRequestConfig = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        },
    };
    return config;
}
const CompanyService = {
    getCompanies, getCompany, postCompany, deleteCompany, patchCompany
};

export default CompanyService;

