import React, { useCallback, useEffect, useRef, useState } from "react";
import FilesUpload from "../components/FilesUpload";
import {
  Button,
  ButtonGroup,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Modal,
  Navbar,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import CandidatesService from "../services/CandidateService";
import { Candidate } from "../types/candidate";
import "./Candidates.css";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import {
  GridOptions,
  SelectionChangedEvent,
  RowDoubleClickedEvent,
  IRowNode,ICellRendererParams
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the grid

import { TagsRenderer } from "../components/table/TagsRenderer";
import { RatingsRenderer } from "../components/table/RatingsRenderer";
import { AvatarRenderer } from "../components/table/AvatarRenderer";
import { DateAgeRenderer } from "../components/table/DateAgeRenderer";
import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";
import Dropzone from "react-dropzone";
import FileUploadService from "../services/FileUploadService";
import { Typeahead } from "react-bootstrap-typeahead";
import CandidateEditor from "../components/candidate/CandidateEditor";
import { EmailRenderer } from "../components/table/EmailRenderer";
import { PhoneRenderer } from "../components/table/PhoneRenderer";
import { LinkedInRenderer } from "../components/table/LinkedInRenderer";

export const Candidates = () => {
  const MISSING_IMAGE = "/projectfiles/avatar_anonymous.png";

  const [candidates, setCandidates] = useState<Array<Candidate>>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<
    Candidate | undefined
  >(undefined);
  const [showCandidate, setShowCandidate] = useState(false);
  const [showNewCandidate, setShowNewCandidate] = useState(false);
  const [showCvUpload, setShowCvUpload] = useState(false);
  const [showBatchPanel, setShowBatchPanel] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const isExternalFilterPresent = useCallback((): boolean => {
    return true;
  }, []);
  const doesExternalFilterPass = useCallback(
    (node: IRowNode<Candidate>): boolean => {
      if (node.data && searchFilter !== "") {
        console.log("searchFilter: ", searchFilter);
        const regexp = new RegExp(searchFilter, "i");
        if (node.data.name && regexp.test(node.data.name)) {
          return true;
        }
        if (node.data.email && regexp.test(node.data.email)) {
          return true;
        }
        if (node.data.tags && regexp.test(node.data.tags.join())) {
          return true;
        }
        return false;
      } else return true;
    },
    [searchFilter]
  );

  const gridApiRef = useRef<AgGridReact<Candidate>>(null);
  const gridOptions: GridOptions<Candidate> = {
    alwaysShowHorizontalScroll: false,
    getRowId: (params) => params.data.id,
    pagination: false,
    rowSelection: "multiple",
    autoSizeStrategy: {
      type: "fitGridWidth",
      defaultMinWidth: 100,
      columnLimits: [
        {
          colId: "summary",
          maxWidth: 200,
        },
      ],
    },
    columnDefs: [
      {
        field: "avatarId",
        cellRenderer: AvatarRenderer,
        headerName: "",
        maxWidth: 65,
        sortable: false,
      },
      { field: "name", headerName: "Namn", maxWidth: 200 },
      { field: "referenceProcessStatus", headerName: "Referens", maxWidth: 170 },
      {
        field: "email",
        headerName: "Epost",
        cellRenderer: EmailRenderer,
        maxWidth: 110,
      },
      {
        field: "phoneNumber",
        headerName: "Telefon",
        cellRenderer: PhoneRenderer,
        maxWidth: 110,
      },
      {
        field: "linkedInUrl",
        headerName: "LinkedIn",
        cellRenderer: LinkedInRenderer,
        maxWidth: 110,
      },
      { field: "tags", headerName: "Taggar", cellRenderer: TagsRenderer,flex: 1},
      { field: "created", maxWidth: 150, headerName: "Skapad", cellRenderer: DateAgeRenderer },
      {
        field: "updated",
        maxWidth: 150,
        headerName: "Uppdaterad",
        cellRenderer: DateAgeRenderer,
        sort: "desc",
      },
      {
        maxWidth: 100,
        cellRenderer: (params:ICellRendererParams<Candidate>) => {
          return (
            <div>
            <Button
              size="sm"
              title="Edit project in mindmap mode"
              variant="link"

              onClick={() => {
                deleteCandidate(params.data!);
              }}
            >
              <i className='bi bi-trash'></i>
            </Button>
            <Button
            size="sm"
            variant="link"
            title="Quick edit"
            
            onClick={() => {
              setSelectedCandidate(params.data);
              setShowCandidate(true);
            }}
          >
            <i className='bi bi-pencil-square'></i>
          </Button>
          </div>
          );
        }
      }
    ],
  };

  const auth = useAuth();
  const handleCloseNewCandidateModal = () => {
    console.log("Closing new candidate modal");
    setShowNewCandidate(false);
    reloadCandidates();
  }
  const handleShowNewCandidateModal = () => setShowNewCandidate(true);
  const handleCloseCandidateModal = () => {
    console.log("Closing new candidate modal");
    setShowCandidate(false);
    reloadCandidates();
  }
  const handleCloseBatchPanel = () => setShowBatchPanel(false);
  const reloadCandidates = () => {
    CandidatesService.getCandidates(auth).then((response) => {
      console.log("Response: ", response);
      setCandidates(response.data);
    });
  };
  useEffect(() => {
    reloadCandidates();
  }, []);
  const [showLoading, setShowLoading] = React.useState<boolean>(false);

  function deleteCandidate(candidate: Candidate) {
    CandidatesService.deleteCandidate(auth, candidate.id).then((response) => {
      console.log("Response: ", response);
      reloadCandidates();
    });
  }
  function onEditButtonClick(params: any) {
    console.log("onEdit", params);
  }

  function toggleShowCvUpload() {
    setShowCvUpload(!showCvUpload);
  }
  function onSelectionChanged(event: SelectionChangedEvent<Candidate>) {
    const scs = event.api.getSelectedRows();
    if (scs.length > 1) {
      setShowBatchPanel(true);
    } else {
      setShowBatchPanel(false);
    }
  }
  function onRowDoubleClicked(event: RowDoubleClickedEvent<Candidate>) {
    setSelectedCandidate(event.data);
    setShowCandidate(true);
  }

  function searchFilterChanged(event: any): void {
    gridApiRef.current!.api.onFilterChanged();
    const searchTerm = event?.target?.value;
    setSearchFilter(searchTerm);
    console.log("searchFilter: ", searchFilter);
  }

  return (
    <>
      <Container className="pt-3">
        <Form className="form-tight">
          <Row className="mt-2">
            <Col>
              <Form.Control
                placeholder="Sök"
                value={searchFilter}
                onChange={searchFilterChanged}
              />
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  onClick={handleShowNewCandidateModal}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Lägg till kandidat"
                >
                  <i className="bi-plus-lg"></i>
                </Button>
                <Button
                  onClick={toggleShowCvUpload}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ladda upp CVn"
                >
                  <i className="bi-file-earmark-arrow-up"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col>
            <Collapse in={showCvUpload}>
              <div>
                <FilesUpload />
              </div>
            </Collapse>
          </Col>
        </Row>

        <Row>
          <Col >
            <div
              className="ag-theme-quartz mt-2" // applying the grid theme
              style={{ height: 500 }} // the grid will fill the size of the parent container
            >
              <AgGridReact<Candidate>
                gridOptions={gridOptions}
                ref={gridApiRef}
                rowData={candidates}
                onSelectionChanged={onSelectionChanged}
                onRowDoubleClicked={onRowDoubleClicked}
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
              />
            </div>
          </Col>
        </Row>

        <Modal show={showNewCandidate} onHide={handleCloseNewCandidateModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Ny Kandidat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CandidateEditor id="" onClose={handleCloseNewCandidateModal}/>
          </Modal.Body>
        </Modal>
        <Modal
          size="xl"
          show={showCandidate}
          onHide={handleCloseCandidateModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedCandidate?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <CandidateEditor id={selectedCandidate?.id!} onClose={handleCloseCandidateModal} onCancel={handleCloseCandidateModal} onSave={handleCloseCandidateModal}/>
          </Modal.Body>
        </Modal>
      </Container>
      {showBatchPanel && (
        <Container fluid>
          <Navbar
            fixed="bottom"
            className="bg-body-tertiary d-flex justify-content-center"
          >
            <Form className="d-flex align-items-center">
              <InputGroup className="me-2">
                <Form.Control placeholder="Taggar" />
                <Button>Applicera</Button>
              </InputGroup>
              <InputGroup className="me-2">
                <Form.Select aria-label="Betyg" aria-placeholder="betyg">
                  <option value="one">&#9733;</option>
                  <option value="two">&#9733;&#9733;</option>
                  <option value="three">&#9733;&#9733;&#9733;</option>
                  <option value="four">&#9733;&#9733;&#9733;&#9733;</option>
                  <option value="five">
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </option>
                </Form.Select>
                <Button>Applicera</Button>
              </InputGroup>
              <InputGroup>
                <Button>
                  <i className="bi bi-envelope"></i>&nbsp;Skicka&nbsp;meddelande
                </Button>
              </InputGroup>
            </Form>
          </Navbar>
        </Container>
      )}
    </>
  );
};
