import React from 'react'
import { Table } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context'

export const Admin = () => {
  const auth = useAuth();
  return (
    <div>
      <p>Admin goes here. Only available to ADMINS</p>
      <Table>
        <tr><td>Scopes</td><td>{auth.user?.scopes}</td></tr>
        
      </Table>
    </div>
  )
}
