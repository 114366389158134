import React, { useEffect } from 'react'
import { CandidateInfoHub, Project, ProjectInfoHub } from '../../types/project';
import Dropzone from 'react-dropzone';
import InfoNodeEditor from './InfoNodeEditor';
import { useAuth } from 'react-oidc-context';
import FileUploadService from '../../services/FileUploadService';
import './ProjectInfoHubEditor.css';
import { Button, FormControl, FormGroup, FormLabel, FormSelect, Modal } from 'react-bootstrap';
import ProjectService from '../../services/ProjectService';


export default function ProjectInfoHubEditor({projectInfoHubId, project, infoHubChanged} : {projectInfoHubId: string, project: Project, infoHubChanged: (infoHub: ProjectInfoHub) => void }) {
  const MISSING_IMAGE = '/projectfiles/image-missing.svg';
  const auth = useAuth();
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [projectInfoHub, setProjectInfoHub] = React.useState<ProjectInfoHub>();
  const [imageSrc, setImageSrc] = React.useState<string>(projectInfoHub?.imageId ? "/api/file/file/" + projectInfoHub.imageId : MISSING_IMAGE);
  
  useEffect(() => {
    if (projectInfoHubId) {
      reload();
    } 
  }, []);
  const reload = () => {  
    console.log("Reloading projectInfoHub. Id: ", projectInfoHubId);
    ProjectService.getProjectInfoHub(auth, projectInfoHubId).then((response) => {
      console.log("Got projectInfoHub: ", response.data);
      setProjectInfoHub(response.data);
      setImageSrc(response.data.imageId ? "/api/file/file/" + response.data.imageId : MISSING_IMAGE);
    }).catch((error) => {
      alert("Error getting projectInfoHub: " + error);
    });
  }
  const onDropAccepted = (acceptedFiles: File[]) => {
    console.log("Accepted files: ", acceptedFiles);
    setImageSrc(MISSING_IMAGE);
    setShowLoading(true);
    FileUploadService.upload(auth, acceptedFiles[0], "image", null).then((response) => {
      setShowLoading(false);
      if (response.status !== 200 && response.data?.length === 1) {
        alert("Error uploading file");
      }
      else {
        const imageId = response.data[0].id;
        console.log("Setting imageId: ", imageId);
        projectInfoHub!.imageId = imageId;
        ProjectService.patchProjectInfoHub(auth, projectInfoHubId, {imageId: imageId}).then((response) => {
          infoHubChanged(response.data);
          console.log("Updated projectInfoHub: ", response.data);
        }).catch((error) => { alert("Error updating projectInfoHub: " + error); });
        setImageSrc("/api/file/file/" + imageId);
      }
    })
  }


  const handleInfoNodeChanged = (infoNode: any) => {  
    
  } 

  if (!projectInfoHub) return (<div>Loading...</div>  );

  

  return (
    <>
      <h4>{projectInfoHub.projectTitle}</h4>
      <Dropzone onDropAccepted={onDropAccepted}>
        {({getRootProps, getInputProps}) => (
          <section >
            <div {...getRootProps()} style={{border: '1px dotted darkgray',display: 'inline-flex', position: 'relative',borderRadius: '8px', padding: '4px'}}>
              <input {...getInputProps()} />
              <img src={imageSrc} className="rounded avatar_img"/>
              {showLoading && <div className="uploading-text">Uploading..</div>}
            </div>
          </section>
        )}
      </Dropzone>
      
      {projectInfoHub.infoNodes?.map((infoNode, index) => {
        return (<InfoNodeEditor className="mt-2" infoNodeId={infoNode.id as string} key={index} project={project} infoNodeChanged={handleInfoNodeChanged}/>);
      })}

    </>
  )
}
