import React from "react";
import "./ProcessSteps.css";
import { ProcessStep } from "../types/process";

export default function ProcessSteps({ steps }: { steps: ProcessStep[] }) {
  return (
    <div className="stepwizard">
      <div className="stepwizard-row">
        {steps.map((step, index) => (
            <div className="stepwizard-step" key={step.id}>
            <button type="button" className={step.completed ? 'btn btn-success btn-circle':'btn btn-light btn-circle'}>
                {step.completed && <i className="bi bi-check"></i>}
                {!step.completed && <span>&nbsp;</span>}
                
            </button>
            <div>{step.title}</div>
            </div>
        ))}
      </div>
    </div>
  );
}
