import { useEffect, useState } from "react";
import "./ProjectPage.css";
import { Button, FormControl, FormGroup, FormLabel, FormSelect, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CandidateInfoHub, InfoHub, InfoNode, Project, ProjectInfoHub } from "../types/project";
import ProjectService from "../services/ProjectService";
import { useAuth } from "react-oidc-context";
import InfoNodeEditor from "../components/project/InfoNodeEditor";
import SvgCandidateInfoHub from "../components/mindmap/SvgCandidateInfoHub";
import SvgProjectInfoHub from "../components/mindmap/SvgProjectInfoHub";
import ProjectInfoHubEditor from "../components/project/ProjectInfoHubEditor";
import CandidateInfoHubEditor from "../components/project/CandidateInfoHubEditor";
import CandidateSelectDialog from "../components/candidate/CandidateSelectDialog";
import { PinchPanHandler } from '../pinchPanHandler';
export const ProjectPage = () => {
  const auth = useAuth();
  const { id } = useParams();
  const [project, setProject] = useState<Project>({
    title: "Loading...",
    id: "0",
    position: "",
    companyName: "",
    companyId: "",
  });
  const [selectedInfoNodeId, setSelectedInfoNodeId] = useState<string>();
  const [selectedProjectInfoHubId, setSelectedProjectInfoHubId] = useState<string>();
  const [selectedCandidateInfoHubId, setSelectedCandidateInfoHubId] = useState<string>();
  const [showAddNodeDialog, setShowAddNodeDialog] = useState(false);

  const [newNodeType, setNewNodeType] = useState<"RichText"|"Media">("RichText");
  const [newNodeTitle, setNewNodeTitle] = useState<string>("Ny");
  const [showAddCandidateDialog, setShowAddCandidateDialog] = useState(false);
  const [sidePanelExpanded, setSidePanelExpanded] = useState(false);
  
  const handleSidePanelClose = () => {
    setSelectedInfoNodeId(undefined);
    setSelectedCandidateInfoHubId(undefined);
    setSelectedProjectInfoHubId(undefined);
  }
  const handleSidePanelExpand = () => {
    setSidePanelExpanded(!sidePanelExpanded);
  }

  const applyNewNode = () => {
    setShowAddNodeDialog(false);
    const infoHubId = selectedProjectInfoHubId ?? selectedCandidateInfoHubId;
    if (!infoHubId) return;
    const newInfoNode : InfoNode = {title: newNodeTitle, type: newNodeType, infoHubId: infoHubId};
    ProjectService.postInfoNode(auth, infoHubId, newInfoNode).then((response) => {
      reloadProject();
    }
    ).catch((error) => {
      alert("Error updating projectInfoHub: " + error);
    });
    
  }
  useEffect(() => {
    console.log("useEffect");
    reloadProject();

    var panAndZoomHandler = new PinchPanHandler(document.getElementById("project-svg-panel") || new HTMLElement(), document.getElementById("project-svg-container") || new HTMLElement());
  }, []);

  const reloadProject = () => {
    console.log("Reloading project");
    if (id) {
      ProjectService.getProject(auth, id)
        .then((response) => {
          const project = response.data;
          console.log("Got project: ", project);
          setProject(project);
        })
        .catch((error) => {
          alert("Error getting project: " + error);
        });
    }
  };

  
  const selectLink = (link: any) => {
    alert("selectLink: " + link);
  };
  const selectInfoNode = (infoNode: InfoNode | undefined) => {
    console.log("selectInfoNode: ", infoNode);
    setSelectedInfoNodeId(infoNode?.id ?? undefined);
    setSelectedCandidateInfoHubId(undefined);
    setSelectedProjectInfoHubId(undefined);
  };
  const selectCandidateInfoHub = (candidateInfoHub: CandidateInfoHub) => {
    console.log("selectCandidateInfoHub: ", candidateInfoHub);
    setSelectedCandidateInfoHubId(candidateInfoHub?.id ?? undefined);
    setSelectedProjectInfoHubId(undefined);
    setSelectedInfoNodeId(undefined);
  };
  const selectProjectInfoHub = (projectInfoHub: ProjectInfoHub) => {
    console.log("selectProjectInfoHub: ", projectInfoHub);
    setSelectedProjectInfoHubId(projectInfoHub?.id ?? undefined);
    setSelectedCandidateInfoHubId(undefined);
    setSelectedInfoNodeId(undefined);
  };

  const handleDelete = () => {  
    if (selectedInfoNodeId) {
      ProjectService.deleteInfoNode(auth, selectedInfoNodeId).then((response) => {
        reloadProject();
        setSelectedInfoNodeId(undefined);
      }).catch((error) => {
        alert("Error deleting infoNode: " + error);
      });
    }
    if (selectedCandidateInfoHubId) {
      ProjectService.deleteInfoHub(auth, selectedCandidateInfoHubId).then((response) => {
        reloadProject();
        setSelectedCandidateInfoHubId(undefined);
      }).catch((error) => {
        alert("Error deleting candidateInfoHub: " + error);
      });
    }
   
  }


  const handleInfoNodeChanged = (infoNode: InfoNode) => {
    reloadProject();
  }
  const handleInfoHubChanged = (infoHub: InfoHub) => {
    reloadProject();
  }
  const handleAddNode = () => {
    setShowAddNodeDialog(true);
  } 
  if (!project) return <div>Loading...</div>;
  const hubWidth = 500;
  const circleRadius = 100;
  const nodeDistance = circleRadius + 40;
  const hubOffset = 50;
  const projectSvgs: any[] = [];

  // Lines
  if (project.candidateInfoHubs) {
    const candidateHubCount = project.candidateInfoHubs.length;
    project.candidateInfoHubs?.forEach((c: CandidateInfoHub, index) => {
      projectSvgs.push(
        <line
          key={c.candidateName + "_line"}
          x1={500}
          y1={250 - hubOffset}
          x2={20 + hubWidth / 2 + ((1000 - 2 * 20) / candidateHubCount) * index}
          y2={400 + hubWidth / 2 - hubOffset}
          className="c_edge"
        ></line>
      );
    });
  }
  // Project hub
  if (project.projectInfoHub) {
    projectSvgs.push(
      <SvgProjectInfoHub
        projectInfoHub={project.projectInfoHub}
        selectInfoNode={selectInfoNode}
        selectProjectInfoHub={selectProjectInfoHub}
        key="project_hub"
        
      ></SvgProjectInfoHub>
    );
  }
  // Candidates
  if (project.candidateInfoHubs) {
    const candidateHubCount = project.candidateInfoHubs.length;
    project.candidateInfoHubs?.forEach((c: CandidateInfoHub, index) => {
      projectSvgs.push(
        <SvgCandidateInfoHub
          candidateInfoHub={c}
          index={index}
          candidateHubCount={candidateHubCount}
          selectCandidateInfoHub={selectCandidateInfoHub}
          selectInfoNode={selectInfoNode}
          key={index}
        />
      );
    });
  }

  function handleAddCandidates(candidateIds: string[]): void {
    if (!project.id) return;
    const patch = { "addCandidates": candidateIds };
    ProjectService.patchProject(auth, project.id, patch).then((response) => {
      reloadProject();
    }
    ).catch((error) => {
      alert("Error updating project: " + error);
    });
  }

  return (
    <div id="project-svg-container" className="project-page-container">
      <aside className={"project-side-panel " + ((selectedInfoNodeId || selectedCandidateInfoHubId || selectedProjectInfoHubId) ? " open " : "") + (sidePanelExpanded ? " expanded ": "")} >
        <div className="project-side-panel-toolbar">
          <button className="symbol expand" onClick={handleSidePanelExpand}></button>
          <button className="symbol close" onClick={ handleSidePanelClose }></button>
        </div>
        <div className="project-side-panel-content">
          {selectedInfoNodeId && <><InfoNodeEditor infoNodeId={selectedInfoNodeId}  project={project} infoNodeChanged={handleInfoNodeChanged}/> </>}
          {selectedProjectInfoHubId && <><ProjectInfoHubEditor projectInfoHubId={selectedProjectInfoHubId}  project={project} infoHubChanged={handleInfoHubChanged}/><Button onClick={handleAddNode} className="mt-2">Lägg till..</Button></>}
          {selectedCandidateInfoHubId && <><CandidateInfoHubEditor candidateInfoHubId={selectedCandidateInfoHubId}  project={project} infoHubChanged={handleInfoHubChanged}/><Button onClick={handleAddNode} className="mt-2">Lägg till..</Button></>}
          <hr></hr>
          <div className="d-flex mt-4">
            <Button variant="primary" onClick={handleDelete}><i className="bi bi-trash"></i></Button>
            <Button variant="primary" hidden={!selectedProjectInfoHubId} onClick={() => setShowAddCandidateDialog(true)} className="ms-2"><i className="bi bi-plus"></i> Lägg till Kandidat</Button>
          </div>
      
        </div>
      </aside>
      <main id="project-svg-panel" className="project-svg-panel" >
        <p>{project?.title}</p>

        <svg viewBox="0 0 1000 1000">
          {projectSvgs}
        </svg>
      </main>
      
      <Modal show={showAddNodeDialog} onHide={()=>setShowAddNodeDialog(false)} >
        <Modal.Header closeButton>
          <Modal.Title>Lägg till info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Välj typ</FormLabel>
            <FormSelect onChange={(e) => setNewNodeType(e.target.value as "Media" | "RichText")}>
              <option value="RichText">Text</option>
              <option value="Media">Media</option>
            </FormSelect>
          </FormGroup>
          <FormGroup>
            <FormLabel>Titel</FormLabel>
            <FormControl onChange={(e) => setNewNodeTitle(e.target.value)} value={newNodeTitle}></FormControl>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={applyNewNode}>Lägg till</Button>
        </Modal.Footer>
      </Modal>
      <CandidateSelectDialog show={showAddCandidateDialog} onApply={handleAddCandidates} onCancel={()=>{console.log("onCancel");setShowAddCandidateDialog(false);}}></CandidateSelectDialog>

     
    </div>
  );
};
