import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';

export class DateAgeRenderer implements ICellRendererComp {
    eGui!: HTMLSpanElement;
  
    init(params: ICellRendererParams) {
      this.eGui = document.createElement('span');
      
      if (params.value) {
        let m = moment.utc(params.value);
        let now = moment.utc();
        let seconds = now.diff(m, 'seconds');
        if (seconds < 60) {
            this.eGui.innerHTML = "<span class='badge text-bg-warning'>nu</span>";
        }
        else if (seconds < 3600) {
          this.eGui.innerHTML =   now.diff(m, 'minutes') + " min sedan";
        }
        else if (seconds < 24*3600) {
            this.eGui.innerHTML =  now.diff(m, 'hours') + " h sedan";
        }
        else {
            this.eGui.innerHTML = m.local().format("YY-MM-DD");
        }
      }
      else this.eGui.innerHTML = "-";
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
  }