import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

export class TagsRenderer implements ICellRendererComp {
    eGui!: HTMLSpanElement;
  
    init(params: ICellRendererParams) {
      this.eGui = document.createElement('div');
      if (Array.isArray(params.value)) {
        let html = "";
        params.value.forEach ((tag) => {
            html += '<span class="badge text-bg-success me-1">' + tag + '</span>'; 
        });
        this.eGui.innerHTML = html;
      }
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
  }