import React, { useEffect, useState } from "react";
import { Modal, Button, Form, FormGroup, Row } from "react-bootstrap";
import ProjectService from "../../services/ProjectService";
import { useAuth } from "react-oidc-context";
import { Project } from "../../types/project";
import { Typeahead } from "react-bootstrap-typeahead";
import ProcessSteps from "../ProcessSteps";
import Dropzone from "react-dropzone";
import FileUploadService from "../../services/FileUploadService";

export default function ProjectEditorDialog({
  show,
  projectId,
  onApply,
  onCancel,
}: {
  show: boolean;
  projectId?: string;
  onApply: () => void;
  onCancel: () => void;
}) {
  const AVATAR_MISSING = "/projectfiles/image-missing.svg";
  const auth = useAuth();
  //const [showModal, setShowModal] = useState<boolean>(show);
  const [edited, setEdited] = useState<Project>({tags: [], coRecruiters: [], companyId: "", companyName: "", recruiter: "", title: "", backgroundImageId: "",  position: "", desiredStartDate: ""});
  const [original, setOriginal] = useState<Project>({tags: [], coRecruiters: [], companyId: "", companyName: "", recruiter: "", title: "", backgroundImageId: "",  position: "", desiredStartDate: ""});
  const [showUploading, setShowUploading] = useState(false);

  const handleApply = () => {
    save();
    onApply();
  };

  const handleCancel = () => {
    onCancel();
  };

  useEffect(() => {
    console.log("UseEffect, 1: ", show);
  }, [show]);

  useEffect(() => {
    console.log("UseEffect, 2: ", projectId);
    if (projectId) {
      reload();
    } else {
      setOriginal({} as Project);
    }
  }, [projectId]);

  const reload = () => {
    if (!projectId) return;
    ProjectService.getProject(auth, projectId).then((response) => {
      console.log("Reloading: ", response);
      setEdited(response.data);
      setOriginal(response.data);
    });
  };

  const onDropAccepted = (acceptedFiles: File[]) => {
    console.log("Accepted files: ", acceptedFiles);
    setShowUploading(true);
    FileUploadService.upload(auth, acceptedFiles[0], "image", null)
      .then((response) => {
        if (response.status !== 200 && response.data?.length === 1) {
          alert("Error uploading file");
        } else {
          const imageId = response.data[0].id;
          console.log("Setting imageId: ", imageId);
          setEdited({ ...edited!, backgroundImageId: imageId });
        }
      })
      .finally(() => {
        setShowUploading(false);
      });
  };


  const save = () => {
    if (original?.id) {
      // Edit mode
      ProjectService.patchProjectByDiff(
        auth,
        original as Project,
        edited as Project
      )
        .then((response) => {
          onApply();
        })
        .catch((error) => {
          alert("Fel: " + error);
        });
    } else {
      // New project mode
      ProjectService.postProject(auth, edited as Project)
        .then((response) => {
          projectId = response.data.id;
          //reload();
          handleApply();
        })
        .catch((error) => {
          alert("Fel: " + error);
        });
    }
  };

  return (
    <>
      <Modal size="xl" show={show} onHide={handleCancel} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
            {edited?.position}, {edited?.companyName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-tight">
            <Row className="g-2">
              <FormGroup className="col-md-4">
                <Form.Label>Projekt-namn</Form.Label>
                <Form.Control
                  type="text"
                  value={edited?.title}
                  onChange={(e) =>
                    setEdited({ ...edited!, title: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Form.Label>Roll</Form.Label>
                <Form.Control
                  type="text"
                  value={edited?.position}
                  onChange={(e) =>
                    setEdited({ ...edited!, position: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Form.Label>Företag</Form.Label>
                <Form.Control
                  type="text"
                  value={edited?.companyName}
                  onChange={(e) =>
                    setEdited({ ...edited!, companyName: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup className="col-4">
                <Form.Label>Rekryterare</Form.Label>
                <Typeahead
                  id="project-recruiter-typeahead"
                  labelKey="name"
                  options={[
                    "Karl Karlsson",
                    "Bo Swedberg",
                    "Sven Nilsson",
                    "Sanna Hedlund",
                    "Anna Karlsson",
                    "Johan Svensson",
                  ]}
                  placeholder="Rekryterare..."
                  onChange={(selected) => {
                    setEdited({ ...edited!, recruiter: selected[0] as string });
                  }}
                  selected={edited?.recruiter ? [edited.recruiter] : []}
                />
              </FormGroup>
              <FormGroup className="col-8">
                <Form.Label>Medrekryterare</Form.Label>
                <Typeahead
                  id="project-co-recruiter-typeahead"
                  labelKey="name"
                  multiple
                  options={[
                    "Karl Karlsson",
                    "Bo Swedberg",
                    "Sven Nilsson",
                    "Sanna Hedlund",
                    "Anna Karlsson",
                    "Johan Svensson",
                  ]}
                  placeholder="Medrekryterare..."
                  onChange={(selected) => {
                    setEdited({
                      ...edited!,
                      coRecruiters: selected as string[],
                    });
                  }}
                  selected={edited?.coRecruiters}
                />
              </FormGroup>
              <FormGroup className="col-3">
                <Form.Label>Önskad start</Form.Label>
                <Form.Control
                  type="text"
                  value={edited?.desiredStartDate}
                  onChange={(e) => {
                    setEdited({ ...edited!, desiredStartDate: e.target.value });
                  }}
                />
              </FormGroup>
              <FormGroup className="col-9">
                <Form.Label>Taggar</Form.Label>
                <Typeahead
                  id="basic-typeahead-multiple"
                  labelKey="name"
                  multiple
                  allowNew
                  options={[]}
                  newSelectionPrefix="Lägg till ny tagg: "
                  placeholder="Tag..."
                  onChange={(selected) => {
                    setEdited({ ...edited!, tags: selected as string[] });
                  }}
                  selected={edited?.tags}
                />
              </FormGroup>
              <FormGroup className="col-12">
                <Form.Label>Rekryteringsstatus</Form.Label>
                <ProcessSteps
                  steps={[
                    {
                      id: "1",
                      title: "Uppstart",
                      completed: true,
                      description: "",
                    },
                    {
                      id: "2",
                      title: "Kravprofil",
                      completed: true,
                      description: "",
                    },
                    {
                      id: "3",
                      title: "Annons",
                      completed: false,
                      description: "Annonseringsperiod/Search",
                    },
                    {
                      id: "4",
                      title: "Intervju 1",
                      completed: false,
                      description:
                        "Genomgång av första urvalet/Screening/Tlfn-intervju",
                    },
                    {
                      id: "5",
                      title: "Intervjuer",
                      completed: false,
                      description:
                        "Kompetensbaserade intervjuer utvalda kandidater",
                    },
                    {
                      id: "6",
                      title: "Kandidat-presentation",
                      completed: false,
                      description: "Presentation av kandidater för kund ",
                    },
                    {
                      id: "7",
                      title: "Kund-intervju",
                      completed: false,
                      description: "",
                    },
                    {
                      id: "8",
                      title: "Referens",
                      completed: false,
                      description: "Referenstagning, tester, case ",
                    },
                    {
                      id: "9",
                      title: "Intervju 2",
                      completed: false,
                      description: "Intervju 2 med kund",
                    },
                    {
                      id: "10",
                      title: "Signering",
                      completed: false,
                      description: "",
                    },
                    {
                      id: "11",
                      title: "Avslutad ",
                      completed: false,
                      description: "",
                    },
                  ]}
                ></ProcessSteps>
              </FormGroup>
            </Row>
            <Row>
            <div>
          <Dropzone
            onDropAccepted={onDropAccepted}
            accept={{
              "image/jpeg": [".jpg", "jpeg"],
              "image/png": [".png", "PNG"],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className="dropzone-container">
                  <input {...getInputProps()} />
                  <img
                    src={
                      edited?.backgroundImageId
                        ? "/api/file/file/" + edited.backgroundImageId
                        : AVATAR_MISSING
                    }
                    className="rounded"
                  />
                  {showUploading && (
                    <div className="uploading-text">Uploading..</div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
          <div className="d-flex">
            <small className="ms-2 text-secondary">Photo</small>
            {edited?.backgroundImageId && (
              <a href="#" className="ms-auto">
                <i
                  className="bi bi-trash"
                  onClick={() => setEdited({ ...edited!, backgroundImageId: "" })}
                ></i>
              </a>
            )}
          </div>
        </div>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleApply}>
            Spara
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
