import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';


export class LogoRenderer implements ICellRendererComp {
    eGui!: HTMLSpanElement;
    init(params: ICellRendererParams) {
      this.eGui = document.createElement('span' );
      
      if (params.value) {
        this.eGui.innerHTML = '<img src="/api/file/file/' + params.value + '" class="" style="max-height: 32px;max-width: 50px"/>';
      }
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
  }