import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Form, Alert, Button, Row  } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { useAuth } from 'react-oidc-context';
import {  } from 'react-router-dom';
import { Company } from '../../types/company';
import CompanyService from '../../services/CompanyService';
import FileUploadService from '../../services/FileUploadService';
import './CompanyEditor.css';

export default function CompanyEditor({
    id,
    onClose,
  }: {
    id?: string;
    onClose?: () => void;
  }) {
    const AVATAR_MISSING = "/projectfiles/avatar-missing.png";
    const auth = useAuth();
    const [original, setOriginal] = useState<Company>();
    const [edited, setEdited] = useState<Company>();
    const [showLoading, setShowLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    useEffect(() => {
        if (id) {
          reload();
        } else {
          // New candidate mode
          setOriginal({} as Company);
          const newCandidate = {} as Company;
        }
      }, []);
      const reload = () => {
        if (!id) return;
        CompanyService.getCompany(auth, id).then((response) => {
          console.log("Reloading: ", response);
          setEdited(response.data);
          setOriginal(response.data);
        });
      };
      const onDropAccepted = (acceptedFiles: File[]) => {
        console.log("Accepted files: ", acceptedFiles);
        setShowLoading(true);
        FileUploadService.upload(auth, acceptedFiles[0], "image", null).then(
          (response) => {
            setShowLoading(false);
            if (response.status !== 200 && response.data?.length === 1) {
              alert("Error uploading file");
            } else {
              const imageId = response.data[0].id;
              console.log("Setting imageId: ", imageId);
              setEdited({ ...edited!, avatarId: imageId });
            }
          }
        );
      };
      const save = () => {
        if (original?.id) {
          // Edit mode
          CompanyService.patchCompany (
            auth,
            original as Company,
            edited as Company
          )
            .then((response) => {
              setErrorMessage("");
              //reload();
              onClose && onClose();
            })
            .catch((error) => {
              setErrorMessage("Fel: " + error);
            });
        } else {
          // New candidate mode
          CompanyService.postCompany(auth, edited as Company)
            .then((response) => {
              id = response.data.id;
              //reload();
              setErrorMessage("");
              onClose && onClose();
            })
            .catch((error) => {
              setErrorMessage("Fel: " + error);
            });
        }
      };
      if (showLoading) return <div>Loading...</div>;
  return (
     <>
      <Form className="row g-3 form-tight">
        <Col md="12" className="d-inline-flex">
            <div>
          <Dropzone onDropAccepted={onDropAccepted} accept={{
                'image/jpeg': ['.jpg','jpeg'],
                'image/png': ['.png','PNG'],
                
            }}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone-container"
                >
                  <input {...getInputProps()} />
                  <img
                    src={
                      edited?.avatarId
                        ? "/api/file/file/" + edited.avatarId
                        : AVATAR_MISSING
                    }
                    className="rounded"
                  />
                  {showLoading && (
                    <div className="uploading-text">Uploading..</div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
          <div className="d-flex">
            <small className="ms-2 text-secondary">Photo</small>
            { edited?.avatarId && <a href="#" className="ms-auto"><i className='bi bi-trash' onClick={() => setEdited({ ...edited!, avatarId: "" })}></i></a> }
          </div>
          </div>
        </Col>
        <Col md="12">
          <FormGroup className="flex-fill">
            <Form.Label>Namn</Form.Label>
            <Form.Control
              value={edited?.name ?? ""}
              onChange={(e) => {
                setEdited({ ...edited!, name: e.target.value });
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="12">
            <FormGroup className="flex-fill">
            <Form.Label>Om</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={edited?.about ?? ""}
              onChange={(e) => {
                setEdited({ ...edited!, about: e.target.value });
              }}
            ></Form.Control>
          </FormGroup>
          </Col>
          
        </Form>
        {errorMessage && (
        <Alert variant="danger" className="mt-2">
          {errorMessage}
        </Alert>
      )}

      <div className="mt-4 gm-2 d-flex justify-content-end">
        <Button onClick={reload}>Ångra</Button>
        <Button className="ms-2" onClick={save}>
          Spara
        </Button>
      </div>
    </>
  )
}
