import React from 'react';
import {HUB_WIDTH, NODE_DISTANCE, HUB_OFFSET, CIRCLE_RADIUS} from './SvgConstants';
import { InfoNode, ProjectInfoHub } from '../../types/project';
import SvgInfoNode from './SvgInfoNode';


export default function SvgProjectInfoHub({projectInfoHub, selectProjectInfoHub, selectInfoNode}:{projectInfoHub: ProjectInfoHub, selectProjectInfoHub: (c: ProjectInfoHub) => void, selectInfoNode: (infoNode: InfoNode) => void}) {
  return (
    <svg
      x={500 - HUB_WIDTH/2}
      y={250 - HUB_WIDTH/2}
      width={HUB_WIDTH}
      height={HUB_WIDTH}
    >
      <g
        onClick={() => selectProjectInfoHub(projectInfoHub)}
      >
        <text
          x={HUB_WIDTH/2}
          width="0"
          y={HUB_WIDTH/2 - HUB_OFFSET - CIRCLE_RADIUS - 40}
          className="c_image_title"
          alignmentBaseline="middle"
          textAnchor="middle"
        >
          {projectInfoHub.projectTitle}
        </text>
        <text
          x={HUB_WIDTH/2}
          width="0"
          y={HUB_WIDTH/2 - HUB_OFFSET - CIRCLE_RADIUS - 20}
          className="c_image_tagline"
          alignmentBaseline="middle"
          textAnchor="middle"
        >
          {projectInfoHub.companyName}{" "}
        </text>
        <circle
          cx={HUB_WIDTH/2}
          cy={HUB_WIDTH/2 - HUB_OFFSET}
          r={CIRCLE_RADIUS}
          className="c_background"
        ></circle>
        <image
          href={'/api/file/file/' + projectInfoHub.imageId}
          height={CIRCLE_RADIUS * 2}
          width={CIRCLE_RADIUS * 2 }
          x={HUB_WIDTH/2 - CIRCLE_RADIUS}
          y={HUB_WIDTH/2 - CIRCLE_RADIUS - HUB_OFFSET}
          className="c_image"
        ></image>
        <circle
          cx={HUB_WIDTH/2}
          cy={HUB_WIDTH/2 - HUB_OFFSET}
          r={CIRCLE_RADIUS}
          className="c_border"
        ></circle>
      </g>
      {projectInfoHub.infoNodes?.map((infoNode:InfoNode, dindex) => {
        return (
          <SvgInfoNode infoNode={infoNode} dindex={dindex} selectInfoNode={selectInfoNode} key={dindex}/>   
        );
      })}
    </svg>
  )
}
