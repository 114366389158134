import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AuthContextProps } from "react-oidc-context";
import { CandidateInfoHub, CreateProjectAction, InfoNode, Project, ProjectInfoHub } from "../types/project";
import { url } from "inspector";
import { Exception } from "sass";
import { arrayEquals } from "../utils";

const getProjects = (auth: AuthContextProps): Promise<any> => {
    return axios.get("/api/project/project", defaultConfig(auth));
};

const getProject = (auth: AuthContextProps, id: string): Promise<AxiosResponse<Project, any>> => {
    return axios.get<Project>("/api/project/project/" + id, defaultConfig(auth));
};
const getProjectInfoHubByProject = (auth: AuthContextProps, projectId: string): Promise<AxiosResponse<ProjectInfoHub, any>> => {   
    return axios.get<ProjectInfoHub>("/api/project/project/" + projectId + "/projectInfoHub", defaultConfig(auth));
}
const getProjectInfoHub = (auth: AuthContextProps, projectInfoHubId: string): Promise<AxiosResponse<ProjectInfoHub, any>> => {   
    return axios.get<ProjectInfoHub>("/api/project/projectInfoHub/" + projectInfoHubId, defaultConfig(auth));
}
const getCandidateInfoHub = (auth: AuthContextProps, candidateInfoHubId: string): Promise<AxiosResponse<CandidateInfoHub, any>> => {   
    return axios.get<CandidateInfoHub>("/api/project/candidateInfoHub/" + candidateInfoHubId, defaultConfig(auth));
}

const getInfoNode = (auth: AuthContextProps, infoNodeId: string): Promise<AxiosResponse<InfoNode, any>> => {
    return axios.get<InfoNode>("/api/project/infoNode/" + infoNodeId, defaultConfig(auth));
}

const deleteProject = (auth: AuthContextProps, id: string): Promise<any> => {
    return axios.delete<Project>("/api/project/project/" + id, defaultConfig(auth));
};

const postProject = (auth: AuthContextProps, data: CreateProjectAction): Promise<any> => {
    
    return axios.post<Project>("/api/project/project", data, defaultConfig(auth));
}
const patchProjectByDiff = (auth: AuthContextProps, original: Project, edited: Project): Promise<any> => {
    const patch:any = {};
    if (original.title !== edited.title) {patch["title"] = edited.title;}
    if (original.position !== edited.position) {patch["position"] = edited.position;}
    if (original.companyId !== edited.companyId) {patch["companyId"] = edited.companyId;}
    if (original.backgroundImageId !== edited.backgroundImageId) {patch["backgroundImageId"] = edited.backgroundImageId;}
    if (original.companyName !== edited.companyName) {patch["companyName"] = edited.companyName;}
    if (original.recruiter !== edited.recruiter) {patch["recruiter"] = edited.recruiter;}
    if (original.desiredStartDate !== edited.desiredStartDate) {patch["desiredStartDate"] = edited.desiredStartDate;}
    if (original.companyContactName !== edited.companyContactName) {patch["companyContactName"] = edited.companyContactName;}
    if (original.companyContactEmail !== edited.companyContactEmail) {patch["companyContactEmail"] = edited.companyContactEmail;}
    if (original.companyContactPhone !== edited.companyContactPhone) {patch["companyContactPhone"] = edited.companyContactPhone;}
    if (original.recruiter !== edited.recruiter) {patch["recruiter"] = edited.recruiter;}
    if (original.coRecruiters !== edited.coRecruiters) {patch["coRecruiters"] = edited.coRecruiters;}
    if (original.status !== edited.status) {patch["status"] = edited.status;}
    if (edited.coRecruiters && !arrayEquals(original.coRecruiters, edited.coRecruiters)) {
        patch["coRecruiters"] = edited.coRecruiters.map((t) => {return t.name ?? t;});
    }
    if (edited.tags && !arrayEquals(original.tags, edited.tags)) {
        patch["tags"] = edited.tags.map((t) => {return t.name ?? t;});
    }
    return axios.patch<Project>("/api/project/project/" + edited.id, patch, defaultConfig(auth));
}
const patchProject = (auth: AuthContextProps, projectId: string, patch: any): Promise<any> => {
    return axios.patch<Project>("/api/project/project/" + projectId, patch, defaultConfig(auth));
}

const postInfoNode = (auth: AuthContextProps, infoHubId: string, data: InfoNode): Promise<any> => {
    
    return axios.post<InfoNode>("/api/project/infoHub/"+infoHubId+"/infoNode", data, defaultConfig(auth));
}

const defaultConfig = (auth: AuthContextProps): AxiosRequestConfig => {
    const config: AxiosRequestConfig = {
        headers: {
            'Authorization': 'Bearer ' + auth.user?.access_token
        },
    };
    return config;
}

const patchProjectInfoHub = (auth: AuthContextProps, infoHubId: string, patch: any): Promise<any> => {
    return axios.patch("/api/project/projectInfoHub/" + infoHubId, patch, defaultConfig(auth));
}
const patchCandidateInfoHub = (auth: AuthContextProps, infoHubId: string, patch: any): Promise<any> => {
    return axios.patch("/api/project/candidateInfoHub/" + infoHubId, patch, defaultConfig(auth));
}
const deleteInfoHub = (auth: AuthContextProps, infoHubId: string): Promise<any> => {
    return axios.delete("/api/project/infoHub/" + infoHubId, defaultConfig(auth));
}


const patchInfoNode = (auth: AuthContextProps, infoNodeId: string, patch: any): Promise<any> => {
    return axios.patch("/api/project/infoNode/" + infoNodeId, patch, defaultConfig(auth));
}
const deleteInfoNode = (auth: AuthContextProps, infoNodeId: string): Promise<any> => {
    return axios.delete("/api/project/infoNode/" + infoNodeId, defaultConfig(auth));
}
const deleteInfoNodeFile = (auth: AuthContextProps, infoNodeId: string, fileId: string): Promise<any> => {
    return axios.delete("/api/project/infoNode/" + infoNodeId + "/file/" + fileId, defaultConfig(auth));
}
const deleteInfoNodeLink = (auth: AuthContextProps, infoNodeId: string, linkId: string): Promise<any> => {
    return axios.delete("/api/project/infoNode/" + infoNodeId + "/link/" + linkId, defaultConfig(auth));
}

const suggestRichTextContent = (auth: AuthContextProps, suggestAction: string, project: Project): Promise<AxiosResponse<string, any>> => {
    if (suggestAction === "FORMAL_REQUIREMENTS") {
        const url = `/api/project/@suggestFormalRequirements?position=${project.position}`;
        return axios.get<string>(url, defaultConfig(auth));
    }
    else throw new Error("Unknown suggestAction: " + suggestAction);
    
};

const ProjectService = {
    getProject, getProjects, postProject, deleteProject, 
    suggestRichTextContent, getInfoNode, patchInfoNode, getProjectInfoHub, 
    getProjectInfoHubByProject, patchProjectInfoHub, getCandidateInfoHub, 
    postInfoNode, deleteInfoNodeFile, deleteInfoNodeLink, patchCandidateInfoHub,
    deleteInfoHub, deleteInfoNode, patchProject, patchProjectByDiff
};

export default ProjectService;

