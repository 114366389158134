import { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  InputGroup,
  Modal,
  Navbar,
  Row,
  Form,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import {
  GridOptions,
  SelectionChangedEvent,
  RowDoubleClickedEvent,ICellRendererParams
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional Theme applied to the grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the grid
import { Project } from "../types/project";
import { TagsRenderer } from "../components/table/TagsRenderer";
import { DateAgeRenderer } from "../components/table/DateAgeRenderer";
import ProjectService from "../services/ProjectService";
import NewProjectForm from "../components/project/NewProjectForm";
import { LogoRenderer } from "../components/table/LogoRenderer";
import ProjectEditorDialog from "../components/project/ProjectEditorDialog";
import ProjectCard from "../components/project/ProjectCard";

export const Projects = () => {
  const { id } = useParams();
  
  const auth = useAuth();
  const navigate = useNavigate();
  
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [showProject, setShowProject] = useState(false);
  const [showNewProject, setShowNewProject] = useState(false);
  const [showBatchPanel, setShowBatchPanel] = useState(false);
  const [viewMode, setViewMode] = useState("cards");

  const handleChangeViewMode = (value: any) => {
    console.log("View mode: ", value);
    setViewMode(value);
  }

  // Grid API
  const gridApiRef = useRef<AgGridReact>(null);
  
  const gridOptions: GridOptions<Project> = {
    getRowId: (params) => params.data.id!,
    pagination: false,
    rowSelection: "multiple",
    autoSizeStrategy: {
      type: "fitGridWidth",
      defaultMinWidth: 100,
    },
    columnDefs: [
      /*
      {
        checkboxSelection: true,
        maxWidth: 50,
      },
      */
      {
        field: "projectInfoHub.imageId",
        cellRenderer: LogoRenderer,
        headerName: "",
        maxWidth: 65,
        sortable: false,
      },
      {
        field: "title",
        headerName: "Projektnamn",
        sortable: true,
        filter: true,
      },
      {
        field: "companyName",
        headerName: "Företag",
        sortable: true,
        filter: true,
      },
      { field: "status", headerName: "Status" },
      { field: "tags", headerName: "Taggar", cellRenderer: TagsRenderer, hide: true },
      { field: "created", headerName: "Skapat", cellRenderer: DateAgeRenderer },
      {
        field: "updated",
        headerName: "Uppdaterat",
        cellRenderer: DateAgeRenderer,
        sort: "desc",
      },
      {
        cellRenderer: (params:ICellRendererParams<Project>) => {
          return (
            <div>
            <Button
              size="sm"
              title="Öppna i mind-map"
              variant="link"
              onClick={() => {
                openProjectPage(params.data!);
              }}
            >
              <i className='bi bi-diagram-3'></i>
            </Button>
            <Button
            size="sm"
            variant="link"
            title="Redigera"
            
            onClick={() => {
              openProjectDialog(params.data!);
            }}
          >
            <i className='bi bi-pencil-square'></i>
          </Button>
          <Button
            size="sm"
            variant="link"
            title="Ta bort"
            
            onClick={() => {
              deleteProject(params.data!);
            }}
          >
            <i className='bi bi-trash'></i>
          </Button>
          </div>
          );
        }
      }
    ],
  };
  const handleCloseNewProjectModal = () => setShowNewProject(false);
  const handleShowNewProjectModal = () => setShowNewProject(true);
  
  const handleNewProjectFormSaved = (project: Project) => {
    setShowNewProject(false);
    reloadProjects();
  }
  

  function openProjectPage(project: Project) {
    if (project) navigate("/project/" + project.id);
  }

  function openProjectDialog(project: Project) {
    setSelectedProject(project);
    setShowProject(true);
  }

  function onProjectEditApply() {
    reloadProjects();
    setShowProject(false);
  }
  function onProjectEditCancel() {
    setShowProject(false);
  }
  

  function onSelectionChanged(event: SelectionChangedEvent<Project>) {
    const scs = event.api.getSelectedRows();
    if (scs.length > 1) {
      setShowBatchPanel(true);
    } else {
      setShowBatchPanel(false);
    }
  }

  function onRowDoubleClicked(event: RowDoubleClickedEvent<Project>) {
    if (event.data?.id) navigate("/project/" + event.data.id);
    //setSelectedProject(event.data);
    //setShowProject(true);
  }

  const reloadProjects = () => {
    ProjectService.getProjects(auth).then((response) => {
      console.log("Response: ", response);
      setProjects(response.data);
    });
  };

  const deleteProject = (project: Project) => {
    if (project?.id) {
      ProjectService.deleteProject(auth, project.id).then((response) => {
        console.log("Response: ", response);
        reloadProjects();
      }).catch((error) => {
        alert("Error deleting project: " + error);
      });  
    }
  }

  useEffect(() => {
    if (projects.length === 0) {
      reloadProjects();
    }
  }, []);
  return (
    <>
      <Container className="pt-3">
        <Form>
          <Row className="mt-2">
            <Col>
              <Form.Control placeholder="Sök" />
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  onClick={handleShowNewProjectModal}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ny Rekrytering"
                >
                  <i className="bi-plus-lg"></i>
                </Button>
              </ButtonGroup>
            </Col>
            <Col className="d-flex justify-content-end">
              <ToggleButtonGroup type="radio" value={viewMode} className="mb-2" name='view-options' onChange={handleChangeViewMode}>
                <ToggleButton id="btn-view-mode-table" value={'table'} name="table">
                  <i className="bi bi-table"></i>
                </ToggleButton>
                <ToggleButton id="btn-view-mode-cards" value={'cards'} name="cards">
                  <i className="bi bi-grid"></i>
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            {viewMode==="table" && (
            <div
              className="ag-theme-quartz mt-2" // applying the grid theme
              style={{ height: 500 }} // the grid will fill the size of the parent container
            >
              <AgGridReact<Project>
                gridOptions={gridOptions}
                ref={gridApiRef}
                rowData={projects}
                onSelectionChanged={onSelectionChanged}
                onRowDoubleClicked={onRowDoubleClicked}
              />
            </div>
            )}
            {viewMode==="cards" && (
              <Row className="">{
                projects.map((project) => (
                  <Col xs={12} sm={6} md={4} lg={3} xl={3} className="mt-2 mb-2" key={project.id}>
                    <ProjectCard project={project} onEditClicked={openProjectDialog} onDeleteClicked={deleteProject} onOpenProjectClicked={openProjectPage}></ProjectCard>
                  </Col>
                ))
              }
              </Row>
            )}
          </Col>
        </Row>

        <Modal
          show={showNewProject}
          onHide={handleCloseNewProjectModal}
          size="lg"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Nytt Projekt</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <NewProjectForm 
              onSave={handleNewProjectFormSaved}
              
            ></NewProjectForm>
          </Modal.Body>
        </Modal>
      <ProjectEditorDialog show={showProject} projectId={selectedProject?.id} onApply={onProjectEditApply} onCancel={onProjectEditCancel} />
      </Container>
      {showBatchPanel && (
        <Container fluid>
          <Navbar
            fixed="bottom"
            className="bg-body-tertiary d-flex justify-content-center"
          >
            <Form className="d-flex align-items-center">
              <InputGroup className="me-2">
                <Form.Control placeholder="Taggar" />
                <Button>OK</Button>
              </InputGroup>
            </Form>
          </Navbar>
        </Container>
      )}
    </>
  );
};
