import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';


export class AvatarRenderer implements ICellRendererComp {
    eGui!: HTMLSpanElement;
    init(params: ICellRendererParams) {
      this.eGui = document.createElement('span' );
      
      if (params.value) {
        this.eGui.innerHTML = '<img src="/api/file/file/' + params.value + '" class="rounded-circle" style="height: 32px;width: 32px;object-fit: cover;transform: none;"/>';
      }
      else {
        this.eGui.innerHTML = '<img src="/projectfiles/avatar_anonymous.png" class="rounded-circle" style="max-height: 40px;max-width: 30px"/>';
      }
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
  }