import moment from "moment";

export function arrayEquals(a:string[] | undefined, b:string[] | undefined) {
    if (a === undefined && b === undefined) return true;
    if (a === undefined || b === undefined) return false;
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export function toAgeString(date: Date) {
    // Dates from server are in UTC
    // Convert to local time
    let m = moment.utc(date).local();
    let now = moment();
    let seconds = now.diff(m, 'seconds');
    if (seconds < 60) {
        return "nu";
    }
    else if (seconds < 3600) {
        return  now.diff(m, 'minutes') + "min sedan";
    }
    else if (seconds < 24*3600) {
        return  now.diff(m, 'hours') + "h sedan";
    }
    else {
        return m.format("YY-MM-DD");
    }
}