import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

export class LinkedInRenderer    implements ICellRendererComp {
    eGui!: HTMLSpanElement;
  
    init(params: ICellRendererParams) {
      this.eGui = document.createElement('div');
      if (params.value) {
        let html = "<a href='" + params.value + "' target='blank_'><i class='bi bi-linkedin'></i></a>";
        this.eGui.innerHTML = html;
      }
    }       
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
  }